div.shorts {
  height: 100vh; 
  overflow: hidden; 
  background-color: var(--bg-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;


  @media (max-width: 480px) {
    margin-top: 0px;
  }

  &__videos {
    height: 87vh; 
    object-fit: cover;
    max-width: 400px;
    aspect-ratio: 9/16;
    // padding-bottom: calc(100% / (9 / 16)); // For browsers without aspect-ratio support
    overflow-y: scroll; // Enable vertical scrolling
    scroll-snap-type: y mandatory;
    // scroll-snap-type: y proximity; // Change from mandatory to proximity
    // scroll-snap-stop: always;
    &::-webkit-scrollbar {
      display: none;
    }

    // for IE Safari
    -ms-overflow-style: none;
    scrollbar-width: none;
    @media (max-width: 1200px) {
      width:47%; // Adjust width for large tablets and smaller desktops
    }

    @media (max-width: 1024px) {
      width: 50%; // Adjust width for tablets and smaller desktops
    }

    @media (max-width: 768px) {
      width: 80%; // Adjust width for mobile landscape
    }

    @media (max-width: 480px) {
      width: 100%;
      height: 87vh;
    }
    @media (max-width: 360px) {
      width: 100%; // Adjust width for mobile portrait
      height: 89vh;
    }
  }
  div.loder-div{
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }
  
      @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
      } 
  }
  div.video-container {
    display: flex;


  div.video-shorts {
    position: relative;
    width: 100%;
    height: 87.5vh;
    margin-top: 10px;
    margin-bottom: 10px;
    scroll-snap-align: start;
    // border-radius: 10px;
    overflow: hidden;
    background-color: #000; // Black

    &__player {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      aspect-ratio: 9/16;
    }

  

   
    .video-js {
      pointer-events: auto; /* Ensure touch events are enabled */
    }
    
    .video-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      pointer-events: auto; /* Ensure touch events are enabled */
      transition: opacity 0.3s ease-in-out;
    }
    
    .hidden {
      opacity: 0;
      visibility: hidden; /* Ensure the element is not clickable */
      pointer-events: none;
    }
    
    .show {
      opacity: 1;
      visibility: visible;
    }
    .pause-icon, .play-icon {
      width: 60px;
      height: 60px;
      background-color: rgba(128, 128, 128, 0.5);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .pause-icon::before, .play-icon::before {
      content: "\25B6"; /* Unicode for play triangle */
      font-size: 30px;
      color: white;
    }
    
    .pause-icon::before {
      content: "\275A\275A"; /* Unicode for pause bars */
    }
    /* Mobile-specific styles */
    @media (max-width: 768px) {
      .pause-icon, .play-icon {
        width: 60px;
        height: 60px;
      }
      .pause-icon::before, .play-icon::before {
        font-size: 25px;
      }
    }
    
    @media (max-width: 640px) {
      .pause-icon, .play-icon {
        width: 40px;
        height: 40px;
      }
      .pause-icon::before, .play-icon::before {
        font-size: 15px;
      }
    }
    
    @media (max-width: 480px) {
      .pause-icon, .play-icon {
        width: 30px;
        height: 30px;
      }
      .pause-icon::before, .play-icon::before {
        font-size: 10px;
      }
    }
    .shortsVideoTop {
      position: absolute;
      min-width: fit-content;
      top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    
    .shortsVideoTopIcon {
      display: flex;
      width: 40%;
      justify-content: space-evenly;
    }
    
    .shortsVideoTopIcon > .MuiSvgIcon-root {
      font-size: 32px;
      padding: 10px;
    }
    
    .shortsVideoSideIcons {
      position: absolute;
      right: 8px;
      bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;


    }
    
    .shortsVideoSideIcon {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      
    }
    
    .shortsVideoSideIcon > .MuiSvgIcon-root {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      padding: 15px;
      font-size: 43px;
      width: 45px;
      height: 45px;
      cursor: pointer;
      // color: rgb(66, 158, 211);
    }
    
    .shortsVideoSideIcon > p {
      color: white;
      font-size: 14px;
      margin-top: 5px;
    }
    .shortDetails {
      display: flex;
      flex-direction: column;
      // align-items: center;
      width: 100%;
      padding: 0 15px;
      position: relative;
    }
    
    .shortDetails p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .show-more-button {
      color: #00f; /* Adjust color as needed */
      cursor: pointer;
      font-size: 14px;
      margin-top: 5px;
    }
    
    
    .shortsBottom {
      display: flex;
      position: absolute;
      flex-direction: column;
      bottom: 10px;
      width: 84%;
      padding: 0 10px;
    }
    
    .shortsDesc {
      display: flex;
      padding: 10px;
      // width: 85%;
    }
    
    .shortsDesc > p {
      color: white;
      font-size: 14px;
    }
    
    .MuiSvgIcon-root {
      cursor: pointer;
      color: white;
    }
    
    .shortDetails {
      display: flex;
      flex-direction: column;
      // align-items: center;
      width: 100%;
      padding: 0 58px;
      position: relative;
    }
    
    .shortDetails p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
    }
    
    .show-more-button {
      color: #00f; /* Adjust color as needed */
      cursor: pointer;
      font-size: 14px;
      margin-top: 5px;
    }
    
    .shortDetails > button {
      padding: 7px;
      background-color: rgba(177, 177, 177, 0.609);
      border: none;
      outline: none;
      color: white;
      font-weight: bold;
      border-radius: 3px;
      cursor: pointer;
    }
    
    .shortDetails > button:hover {
      background-color: rgb(255, 0, 0);
    }
    
    .shortsVideoSideIcon > .MuiSvgIcon-root:hover {
      color: blue;
    }
    
    .shortsDesc > .ticker {
      height: fit-content;
      width: 100%;
      max-width: 250px;
    }
    
    .description {
      color: white;
    }
    
    // .shortsVideoSideIcon > .MuiSvgIcon-root:last-child {
    //   bottom: 0;
    //   right: 20px;
    //   font-size: 58px;
    //   // z-index: 10;
    // }
  }
div.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;


  // .share-modal-overlay {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.7);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   z-index: 1000;
  // }
  
  .share-modal-content {
    background: #202020;
    border-radius: 12px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    top: 260px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    color: white;
  }
  
  .share-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
  }
  
  .share-options {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .share-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    margin: 0 10px;
    transition: opacity 0.3s;
    border: none;
    cursor: pointer;
  
    &:hover {
      opacity: 0.8;
    }
  }
  
  .facebook { background: #3b5998; }
  .twitter { background: #1da1f2; }
  
  .share-url-container {
    display: flex;
    margin-top: 15px;
  }
  
  .share-url-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 4px 0 0 4px;
    background: #333;
    color: white;
  }
  
  .copy-button {
    padding: 10px 15px;
    background: #1da1f2;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background 0.3s;
  
    &:hover {
      background: #0c8de4;
    }
  }
  }
  div.comment-modal {
    position: fixed;
    top: 76px;
    width: 28%;
    right: 8%;
   
    height: 88vh;
    background-color: #212121;
    z-index: 1000;
    border-radius: 10px;
    // animation: slideInFromLeft 1s ease-out;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    // border-top-right-radius: 25px;
    // border-bottom-right-radius: 25px;
    @media (max-width: 1400px) {
      width: 28%;
      right: 9%;
  }

    @media all and (max-width: 1200px) {
      width: 60%;
    }
  
    @media all and (max-width: 768px) {
      width: 80%;
      // padding: 10px;
    }
  
    @media all and (max-width: 640px) {
      width: 100%;
      right: 0;
      top: 58px;
      // padding: 40px;
      height: 88vh;
    }
 
  
  div.comment-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
 
  
  .comment-modal-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    .header-actions {
      display: flex;
      align-items: center;

      .sort-button {
        position: relative;
        margin-right: 15px;

        button {
          background: transparent;
          border: none;
          color: var(--white-color);
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;

          .sort-icon {
            margin-left: 5px;
            font-size: 10px;
          }
        }

        .sort-dropdown {
          position: absolute;
          top: 100%;
          right: 0;
          background-color: #282828;
          border-radius: 4px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
          z-index: 1000;

          div {
            padding: 10px 15px;
            color: var(--white-color);
            cursor: pointer;
            white-space: nowrap;

            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
    @media all and (max-width: 1200px) {
      width: 100%;
    }

    @media all and (max-width: 768px) {
      width: 100%;
    }

    @media all and (max-width: 640px) {
      width: 100%;
    }
  }
  
  .comment-modal-header h2 {
    font-size:20px;
    font-weight: 600;
    margin: 0;
    color: var(--white-color);
  }
  
  .close-modal {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: var(--white-color);
  }
  
  div.comment-modal-list {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 16px;
    div.loder-div{
      display: flex;
      justify-content: center;
      align-items: center;
      .loader {
        width: 48px;
        height: 48px;
        border: 5px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        }
    
        @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        } 
    }
    
    div.bottom {
      div.comments {
          div.eachComment {
              border-bottom: 1px solid var(--border-color);

              &:last-child {
                  border: none;
              }

              div.containers {
                  padding: 10px 0 25px;

                  div.replyMessagesContainer {
                      padding: 40px 0 0;

                      div.containers {
                          padding: 0 0 0;
                          display: flex;
                          border: none;
                      }

                  }

                  div.commentItem {
                      display: flex;

                      &:last-child {
                          border: none;
                      }

                      div.left {
                          width: 45px;
                          margin-right: 20px;

                          div.profileImageContainer {
                              width: 100%;
                              height: 45px;

                              @media all and (max-width:640px) {
                                  height: 41px;
                              }

                              img {
                                  width: 100%;
                                  display: block;
                                  height: 100%;
                                  border-radius: 50%;

                              }
                          }

                          span.nameAvatar {
                              width: 100%;
                              height: 45px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              color: var(--white-color);
                              background-color: green;
                              border-radius: 50%;
                          }
                      }

                      div.right {
                          width: 90%;

                          @media all and (max-width:640px) {
                              width: 96%;
                          }

                          h1.name {
                              font-size: 16px;
                              color: var( --white-color);
                          }

                          span.time {
                              font-size: 13px;
                              color: var(--inner-text-color);
                              font-weight: 600;
                              display: inline-block;
                              margin-bottom: 20px;
                          }

                          p.comment {
                              font-size: 16px;
                              color: var( --white-color);
                              margin-bottom: 20px;
                              white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word; 
    line-height: 1.5; 
    max-width: 100%;
                          }
                          div.comment-image-container {
                            margin-top: 10px;
                            margin-bottom: 30px;
                            width: 30%;
                            height: auto;
                            img {
                              max-width: 100%;
                              height: auto;
                              border-radius: 8px;
                              
                              }
                            }
                          div.actions {
                              display: flex;

                              span.item {
                                  display: flex;
                                  align-items: center;
                                  margin-right: 20px;
                                  font-size: 11px;
                                  color: var(--inner-text-color);
                                  transition: 0.4s ease-in-out;
                                  cursor: pointer;

                                  &:hover {
                                      opacity: 0.6;
                                  }

                                  &:last-child {
                                      margin-right: 0;
                                  }

                                  span {
                                      svg {
                                          width: 15px !important;
                                          height: 15px !important;
                                          color: rgb(190, 190, 187);
                                          fill: rgb(190, 190, 187);
                                          margin-right: 5px;
                                      }
                                  }
                              }
                         
                          
                          }
                      }
                  }

              }

          }
      }
  }
    div.commentsContainer {
      width: 70%;
      margin: 0 auto;
      padding: 60px 0;

      @media all and (max-width:1200px) {
          width: 80%;
      }

      @media all and (max-width:768px) {
          width: 90%;
      }

      @media all and (max-width:640px) {
          width: 100%;
      }

      div.top {
          div.head {
              display: none;

              // span.commentsCount {
              //     color: var(--white-color);
              //     font-size: 22px;

              //     @media all and (max-width:640px) {
              //         font-size: 16px;
              //     }
              // }

              // div.sortContainer {
              //     select {
              //         background-color: transparent;
              //         border: 1px solid var(--border-color);
              //         padding: 10px 15px;
              //         color: var(--white-color);
              //         font-size: 16px;
              //         border-radius: 6px;

              //         option {
              //             color: var(--black-color);
              //         }
              //     }
              // }
          }

          div.textContainer {
              display: none;
              // padding: 40px 0 20px;
              // border-bottom: 1px solid var(--border-color);

              // div.imageContainer {
              //     margin-right: 20px;
              //     width: 45px;
              //     height: 45px;

              //     img {
              //         height: 100%;
              //         width: 100%;
              //         border-radius: 50%;
              //     }

              //     span.nameAvatar {
              //         width: 45px;
              //         height: 45px;
              //         display: flex;
              //         justify-content: center;
              //         align-items: center;
              //         color: var(--white-color);
              //         background-color: green;
              //         border-radius: 50%;
              //     }
              // }

              // div.inputContainer {
              //     width: 90%;

              //     textarea {
              //         display: block;
              //         width: 100%;
              //         height: 45px;
              //         padding: 10px 15px;
              //         border-radius: 5px;
              //         margin-bottom: 20px;

              //         &:focus-visible {
              //             border: 3px solid var(--button-color) !important;
              //             outline: none;
              //         }
              //     }

              //     div.actions {
              //         display: flex;
              //         justify-content: space-between;

              //         p {
              //             font-size: 14px;
              //             color: var(--text-color);

              //             span {
              //                 color: var(--grey-color);
              //                 cursor: pointer;

              //                 &:hover {
              //                     text-decoration: underline;
              //                 }
              //             }
              //         }

              //         div.buttonContainer {
              //             button.submit {
              //                 background-color: var(--button-color);
              //                 color: var(--white-color);
              //                 font-weight: 600;
              //                 padding: 10px 20px;
              //                 border-radius: 6px;
              //                 font-size: 14px;
              //                 cursor: pointer;
              //                 border: 1px solid var(--button-color);
              //                 transition: 0.4s ease-in-out;

              //                 &:hover {
              //                     background-color: transparent;
              //                 }
              //             }
              //         }
              //     }
              // }
          }
      }

      div.bottom {
          div.comments {
              div.eachComment {
                  border-bottom: 1px solid var(--border-color);

                  &:last-child {
                      border: none;
                  }

                  div.containers {
                      padding: 40px 0 25px;

                      div.replyMessagesContainer {
                          padding: 40px 0 0;

                          div.containers {
                              padding: 0 0 0;
                              display: flex;
                              border: none;
                          }

                      }

                      div.commentItem {
                          display: flex;

                          &:last-child {
                              border: none;
                          }

                          div.left {
                              width: 45px;
                              margin-right: 20px;

                              div.profileImageContainer {
                                  width: 100%;
                                  height: 45px;

                                  @media all and (max-width:640px) {
                                      height: 41px;
                                  }

                                  img {
                                      width: 100%;
                                      display: block;
                                      height: 100%;
                                      border-radius: 50%;

                                  }
                              }

                              span.nameAvatar {
                                  width: 100%;
                                  height: 45px;
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--white-color);
                                  background-color: green;
                                  border-radius: 50%;
                              }
                          }

                          div.right {
                              width: 90%;

                              @media all and (max-width:640px) {
                                  width: 96%;
                              }

                              h1.name {
                                  font-size: 16px;
                                  color: var( --white-color);
                              }

                              span.time {
                                  font-size: 13px;
                                  color: var(--inner-text-color);
                                  font-weight: 600;
                                  display: inline-block;
                                  margin-bottom: 20px;
                              }

                              p.comment {
                                  font-size: 16px;
                                  color: var( --white-color);
                                  margin-bottom: 20px;
                                  margin-bottom: 20px;
                                  white-space: normal;
        overflow-wrap: break-word;
        word-break: break-word; 
        line-height: 1.5; 
        max-width: 100%;
                              }

                              div.actions {
                                  display: flex;

                                  span.item {
                                      display: flex;
                                      align-items: center;
                                      margin-right: 20px;
                                      font-size: 11px;
                                      color: var(--inner-text-color);
                                      transition: 0.4s ease-in-out;
                                      cursor: pointer;

                                      &:hover {
                                          opacity: 0.6;
                                      }

                                      &:last-child {
                                          margin-right: 0;
                                      }

                                      span {
                                          svg {
                                              width: 15px !important;
                                              height: 15px !important;
                                              color: rgb(190, 190, 187);
                                              fill: rgb(190, 190, 187);
                                              margin-right: 5px;
                                          }
                                      }
                                  }
                              }
                          }
                      }

                  }

              }
          }
      }
  }
  }
  
  .comment-item {
    display: flex;
    margin-bottom: 16px;
  }
  
  .comment-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  
  .comment-body {
    flex: 1;
  }
  
  .comment-author {
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .comment-text {
    margin-bottom: 4px;
  }
  
  .comment-actions {
    display: flex;

    align-items: center;
    color: #606060;
    font-size: 14px;
  }
  
  .comment-like {
    margin-right: 8px;
  }
  
  .comment-replies {
    color: #065fd4;
    cursor: pointer;
  }
  .comment-modal-footer {
    padding: 16px;
    border-top: 1px solid var(--border-color);
  
    .comment-input-wrapper {
      display: flex;
      align-items: flex-start;
      span.nameAvatar {
        width: 10%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white-color);
        background-color: green;
        border-radius: 50%;
    }
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
      }
  
      .comment-input-container {
        flex-grow: 1;
        textarea {
          display: block;
          width: 100%;
          height: 45px;
          padding: 10px 15px;
          border-radius: 5px;
          margin-bottom: 20px;
          border: 1px solid #e1e8ed;
          resize: vertical !important;
          &:focus-visible {
            border: 3px solid var(--button-color) !important;
            outline: none;
            resize: vertical;
          }
        }
      div.upload-featue{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;
        div.selectedImage {
          width: 55%;
          height: 30%;
          margin-bottom: 20px;
          img {
            max-width: 55%;
            border-radius: 5px;
            height: auto;
          }
          button.removeButton {
            position: absolute;
            background: white;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            font-size: 20px;
            color: var(--button-color);
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s ease;
          
            &:hover {
              background-color: #f2f2f2;
            }
          
            svg {
              width: 15px;
              height: 15px;
              fill: var(--button-color);
            }
          }
    
          
        }
     
        div.actions {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
      
          .buttonContainer {
            display: flex;
            align-items: center;
            margin-right: 10px;

      
            label.customFileUpload {
              background-color: #1da1f2;
              color: white;
              margin-right: 10px;
              font-weight: 600;
              padding: 7px 20px;
              border-radius: 6px;
              font-size: 14px;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1da1f2;
              transition: 0.4s ease-in-out;
            
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
      
            input[type="file"] {
              display: none;
            }
      
            button.giphyButton {
              background-color: #1da1f2;
              color: white;
              font-weight: 600;
              padding: 7px 20px;
              border-radius: 6px;
              font-size: 14px;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1da1f2;
              transition: 0.4s ease-in-out;
           
              &:hover {
                background-color: transparent;
                color: var(--white-color);
              }
            }
          }
      
          button.submit {
            background-color: var(--button-color);
            color: var(--white-color);
            font-weight: 600;
            padding: 10px 20px;
            border-radius: 6px;
            font-size: 14px;
            cursor: pointer;
            border: 1px solid var(--button-color);
            transition: 0.4s ease-in-out;
            &:hover {
              background-color: transparent;
              color: var(--white-color);
            }
          }
        }
      }
       
        .add-comment {
          width: 100%;
          padding: 12px;
          border: 1px solid var(--border-color);
          border-radius: 4px;
          font-size: 14px;
          background-color: var(--input-bg-color);
          color: var(--text-color);
          margin-bottom: 8px;
  
          &:focus {
            outline: none;
            border-color: var(--focus-color);
          }
        }
  
        .comment-actions {
          display: flex;
          justify-content: flex-end;
           align-items: center;
           label.customFileUpload{
            width: 50%;
            // margin-bottom: 10px;
            text-align: center;
            border-radius: 50%;
            transition: background-color 0.2s;
            border: 1px solid #1da1f2;
            // justify-content: center;
            padding: 12px;
            margin: 10px;

          }
          .action-button {
            background: none;
            // border: none;
            cursor: pointer;
            color: var(--icon-color);
            padding: 10px;
            border-radius: 50%;
            transition: background-color 0.2s;
            border: 1px solid #1da1f2;
  
            &:hover {
              background-color: var(--hover-bg-color);
            }
            
          }
  
          .submit-button {
            background:none;
            color: var(--button-text-color);
            border: none;
            padding: 8px 16px;
            border-radius: 18px;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.2s;
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
  
            &:not(:disabled):hover {
              background-color: var(--button-hover-color);
            }
          }
        }
      }
    }
  
    .image-preview {
      margin-top: 12px;
      position: relative;
      display: inline-block;
  
      img {
        max-width: 100%;
        max-height: 200px;
        border-radius: 4px;
      }
  
      .remove-preview {
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        border: none;
        border-radius: 50%;
        padding: 4px;
        cursor: pointer;
      }
    }
  }
  // .comment-modal-footer {
  //   padding: 16px;
  //   border-top: 1px solid #e0e0e0;
  // }
  
  .add-comment {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
  }
 

}
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;

    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
    }

    div.customModal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    
      .modalContent {
        background: white;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        max-width: 90%;
        max-height: 80%;
        overflow-y: auto;
    
        .closeButton {
          position: absolute;
          top: -6px;
          right: 2px;
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: initial;
  height: 100% !important;
  width: 100% !important;
}
