div.pdfCard{
    width: 100%;
    cursor: pointer;
    div.imageContainer{
        width: 100%;
        margin-bottom: 10px;
        img{
            width: 100%;
            display: block;
        }
    }
    h1.pdfName{
        font-size: 16px;
        color: var(--text-color);
        font-weight: 400;
    }
    span.size{
        font-size: 14px;
        color: var(--text-color);

    }
}