div.commonPage {
  padding: 130px 0 40px;
  width: 100%;
  h1.heading{
    color: var(--heading-color);
  }
  div.bgImageContainer {
    width: 100%;
    display: block;
    height: 508px;
    position: relative;

    @media all and (max-width: 768px) {
      height: 556px;
    }
    div.top {
      padding-bottom: 60px;
      position: absolute;
      top: 50%;
      z-index: 8;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 85%;
      @media all and (max-width: 980px) {
       flex-direction: column-reverse;
       align-items: flex-start;
      }
      
      div.left{
        width: 50%;
        @media all and (max-width: 980px) {
          width: 100%;
          margin-top: 20px;
         }
        h1.heading {
          font-size: 32px;
          margin-bottom: 10px;
          // margin-top: 20px;
          color: var(--text-color);
          @media all and (max-width: 640px) {
            font-size: 22px;
            // margin-top: 50px;
          }
        }
        p.desc {
          font-size: 18px;
          // color: var(--inner-text-color);
          color: whitesmoke;
          @media all and (max-width: 640px) {
            font-size: 14px;
          }
        }
        div.buttonContainer {
          display: flex;
          padding: 10px 20px;
          border-radius: 4px;
          align-items: center;
          margin-bottom: 15px;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          justify-content: center;
          position: relative;
          width: 10rem;
          margin-top: 20px;
          position: relative;
          div.shareContainer {
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 9;
            background-color: #1c1f29;
            border: 1px solid var(--border-color);
            width: 232px;
            border-radius: 8px;
            display: none;
            @media all and (max-width: 1400px) {
               top: 60px;
               width: 200px;
              }
            @media all and (max-width: 1200px) {
              width: 200px;
            }
            @media all and (max-width: 980px) {
              left: 50%;
              transform: translateX(-50%);
            }
            @media all and (max-width: 480px) {
                width: 170px;
    
            }
            &.active {
              display: block;
            }
            &::after {
              content: "";
              top: -9px;
              width: 0;
              height: 0;
              border-color: transparent transparent rgba(240, 241, 245, 0.16);
              border-style: solid;
              border-width: 0 8px 8px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              @media all and (max-width: 1200px) {
              }
              @media all and (max-width: 980px) {
                left: 50%;
              }
            }
            div.socialContainer{
                &:last-child{
                    button{
                        border-bottom: none !important;
                    }
                }
               
                button {
                    display: flex !important;
                    justify-content: space-between !important;
                    padding: 15px !important;
                    color: var(--text-color) !important;
                    cursor: pointer !important;
                    border-bottom: 1px solid var(--border-color) !important;
                    width: 100% !important;
                    &:hover{
                        div.icon{
                            i{
                                color: var(--text-color);
                            }
                            svg{
                                color: var(--text-color) !important;
                            }
                        }
                    }
                    div.icon {
                      width: 10%;
                      i{
                        color: var(--grey-color);
                        transition: 0.3s ease-in-out;
    
                      }
                      svg {
                        width: 20px !important;
                        height: 20px !important;
                        color: var(--grey-color);
                        transition: 0.3s ease-in-out;
                        &:hover {
                          color: var(--text-color);
                        }
                      }
                    }
                  }
                  div.left {
                    width: 10%;
                  }
                  div.right {
                    svg {
                      width: 20px !important;
                      height: 20px !important;
                      color: var(--grey-color);
                      transition: 0.3s ease-in-out;
                      &:hover {
                        color: var(--text-color);
                      }
                    }
                  }
            }
            div.facebookContainer {
              // display: flex;
              // justify-content: space-between;
              // padding: 15px;
              // color: var(--text-color);
              // cursor: pointer;
              // border-bottom: 1px solid var(--border-color);
              button.facebook {
                display: flex !important;
                justify-content: space-between !important;
                padding: 15px !important;
                color: var(--text-color) !important;
                cursor: pointer !important;
                border-bottom: 1px solid var(--border-color) !important;
                width: 100% !important;
                div.icon {
                  width: 10%;
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    color: var(--grey-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      color: var(--text-color);
                    }
                  }
                }
              }
              div.left {
                width: 10%;
              }
              div.right {
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
            div.twitterContainer {
              // display: flex;
              // justify-content: space-between;
              // padding: 15px;
              // color: var(--text-color);
    
              // cursor: pointer;
              button.twitter {
                display: flex !important;
                justify-content: space-between !important;
                padding: 15px !important;
                color: var(--text-color) !important;
                cursor: pointer !important;
                // border-bottom: 1px solid var(--border-color) !important;
                width: 100% !important;
                div.icon {
                  width: 10%;
                  svg {
                    width: 20px !important;
                    height: 20px !important;
                    color: var(--grey-color);
                    transition: 0.3s ease-in-out;
                    &:hover {
                      color: var(--text-color);
                    }
                  }
                }
              }
              div.left {
                width: 10%;
              }
              div.right {
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
          }
          span.icon{
            margin-right: 10px;
            display: inline-block;
          }
          @media all and (max-width: 1280px) {
            width: 11rem;
            padding: 13px 19px;
          }
          @media all and (max-width: 480px) {
            width: 8rem;
            padding: 9px 19px;
          }
          &:hover {
            div.background {
              transform: scale(1.0476190476, 1.0357142857);
            }
          }
          div.background {
            background: var(--button-color);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 4px;
            transition: 0.1s ease-in-out;
          }
          button {
            background: transparent;
            font-size: 15px;
            color: var(--text-color);
            font-weight: 600;
            z-index: 1;
            cursor: pointer;
          }
        }
      }
      div.right{
        width: 36%;
        @media all and (max-width: 1400px) {
          width: 40%;
        }
        @media all and (max-width: 1200px) {
          width: 45%;
        }
        @media all and (max-width: 980px) {
          width: 60%;
         }
         @media all and (max-width: 640px) {
          width: 70%;
         }
         @media all and (max-width: 480px) {
          width: 100%;
         }
        div.smallBanner{
          width: 100%;
          img{
            width: 100%;
            display: block;
          border-radius: 10px;
          }
        }
      }
    }
    div.gradientBanner{
      height: 100%;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: blur(10px);
        -webkit-filter: blur(10px);
        overflow: hidden;
        @media all and (max-width: 768px) {
          object-fit: cover;
        }
      }

    }
    
  }
  
  div.bottomGradient {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%),
      linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
  }
  div.bottom {
    margin-top: 40px;
    div.itemsContainer {
      display: flex;
      flex-wrap: wrap;
      div.items {
        &.rowCount3 {
          width: 32.5%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          @media all and (max-width: 1200px) {
            width: 32%;
          }
        }
        &.rowCount4 {
          width: 24%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          @media all and (max-width: 1200px) {
            width: 23.8%;
          }
        }
        &.rowCount5 {
          width: 19%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(5n) {
            margin-right: 0;
          }
          @media all and (max-width: 1200px) {
            width: 18.5%;
          }
        }
        &.rowCount6 {
          width: 15.8%;
          margin-right: 13px;
          margin-bottom: 30px;
          &:nth-child(6n) {
            margin-right: 0;
          }
          @media all and (max-width: 1400px) {
            width: 15.5%;
          }
          @media all and (max-width: 1200px) {
            width: 15%;
          }
        }
        &.portrait {
          // @media all and (max-width:1400px){
          //     width: 15.6%;
          // }
          // @media all and (max-width:1200px){
          //     width: 18.8%;
          //     &:nth-child(5n){
          //         margin-right: 0;
          //     }
          //    &:nth-child(6n){
          //     margin-right: 13px;
          //    }
          // }
          @media all and (max-width: 980px) {
            width: 23.5%;
            margin-right: 13px;
            margin-bottom: 40px;
            &:nth-child(4n) {
              margin-right: 0;
            }
            &:nth-child(5n) {
              margin-right: 13px;
            }
          }
          @media all and (max-width: 768px) {
            width: 23%;
            &:nth-child(4n) {
              margin-right: 0;
            }
            &:nth-child(5n) {
              margin-right: 13px;
            }
          }
          @media all and (max-width: 640px) {
            width: 31%;

            &:nth-child(4n) {
              margin-right: 13px;
            }
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
          @media all and (max-width: 480px) {
            width: 47%;

            &:nth-child(3n) {
              margin-right: 13px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
        &.landscape {
          // width: 32.8%;

          // &:nth-child(3n){
          //     margin-right: 0;
          // }
          // @media all and (max-width:1400px){
          //     width: 32.5%;
          // }
          // @media all and (max-width:1200px){
          //     width:32%;
          // //     &:nth-child(4n){
          // //         margin-right: 0;
          // //     }
          // //    &:nth-child(5n){
          // //     margin-right: 13px;
          // //    }
          // }
          @media all and (max-width: 980px) {
            width: 32%;
            margin-right: 13px;
            margin-bottom: 40px;
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
          @media all and (max-width: 768px) {
            width: 47.6%;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(3n) {
              margin-right: 13px;
            }
          }
          @media all and (max-width: 640px) {
            width: 48%;

            &:nth-child(3n) {
              margin-right: 13px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          @media all and (max-width: 480px) {
            width: 47%;

            &:nth-child(3n) {
              margin-right: 13px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
      div.magazineCard{
        width: 50%;
        &.rowCount4 {
          width: 17%;
          margin-right: 13px;
          margin-bottom: 30px;
      }
      @media all and (max-width: 980px) {
        width: 31.8%;
        margin-right: 15px;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media all and (max-width: 768px)
      {
        width: 31.8%;
        margin-right: 12px;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media all and (max-width: 640px)
      {
        width: 31%;
      }
      @media all and (max-width: 480px)
      {
        width: 46.9%;
        margin-right: 12px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media all and (max-width: 360px)
      {
        width: 47%;
        margin-right: 16px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      
      }
    }
  }
}
