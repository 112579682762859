div.languageContainer {
    align-items: center;
    padding-top: 5px;
    background-color: var(--bg-color);
    label {
        font-size: 15px;
        color: var(--inner-text-color);

        @media all and (max-width:480px) {
            font-size: 13px;
        }
    }

    select {
        margin-top: 3px;
        cursor: pointer;
        width: 168px;
        height: 25px;
        border-radius: 3px;
        margin-bottom: 10px;
        background: transparent;
        color: #fff;

        @media all and (max-width:360px) {
            width: 125px;
        }

        @media all and (max-width:320px) {
            width: 100px;
        }

        option {
            background-color: #1c1f29;
            color: white;
            font-size: 12px;
        }
    }


}