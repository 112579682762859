div.myList{
    padding: 130px 0 40px;
    div.top{
        padding-bottom: 60px;
        border-bottom: 1px solid var(--border-color);
        h1.heading{
            font-size: 46px;
            margin-bottom: 10px;
            color: var(--text-color);
            @media all and (max-width:640px){
                font-size: 36px;
            }
        }
        p.desc{
            font-size: 18px;
            color: var(--inner-text-color);
            @media all and (max-width:640px){
                font-size: 14px;
            }
        }
    }
    div.bottom{
        margin-top: 40px;
        div.itemsContainer{
            display: flex;
            flex-wrap: wrap;
            div.items{
                &.rowCount3{
                    width: 32.5%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 32%;
                    }
                }
                &.rowCount4{
                    width: 24%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 23.8%;
                    }
                }
                &.rowCount5{
                    width: 19%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(5n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 18.5%;
                    }
                }
                &.rowCount6{
                    width: 15.8%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(6n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1400px){
                        width: 15.5%;
                    }
                    @media all and (max-width:1200px){
                        width: 15%;
                    }
                }
                &.portrait{
                  
                    // @media all and (max-width:1400px){
                    //     width: 15.6%;
                    // }
                    // @media all and (max-width:1200px){
                    //     width: 18.8%;
                    //     &:nth-child(5n){
                    //         margin-right: 0;
                    //     }
                    //    &:nth-child(6n){
                    //     margin-right: 13px;
                    //    }
                    // }
                    @media all and (max-width:980px){
                        width:23.5%;
                        margin-right: 13px;
                        margin-bottom: 40px;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                       &:nth-child(5n){
                        margin-right: 13px;
                       }
                    }
                    @media all and (max-width:768px){
                        width:23%;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                       &:nth-child(5n){
                        margin-right: 13px;
                       }
                    }
                    @media all and (max-width:640px){
                        width:31%;
                        
                       &:nth-child(4n){
                        margin-right: 13px;
                       }
                       &:nth-child(3n){
                        margin-right: 0;
                    }
                    }
                    @media all and (max-width:480px){
                        width:47%;
                        
                       &:nth-child(3n){
                        margin-right: 13px;
                       }
                       &:nth-child(2n){
                        margin-right: 0;
                    }
                    }
                }
                &.landscape{
                    // width: 32.8%;
               
                // &:nth-child(3n){
                //     margin-right: 0;
                // }
                // @media all and (max-width:1400px){
                //     width: 32.5%;
                // }
                // @media all and (max-width:1200px){
                //     width:32%;
                // //     &:nth-child(4n){
                // //         margin-right: 0;
                // //     }
                // //    &:nth-child(5n){
                // //     margin-right: 13px;
                // //    }
                // }
                @media all and (max-width:980px){
                    width:32%;
                    margin-right: 13px;
                margin-bottom: 40px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    
                }
                @media all and (max-width:768px){
                    width:47.6%;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    &:nth-child(3n){
                        margin-right: 13px;
                    }
                }
                @media all and (max-width:640px){
                    width:48%;
                    
                   &:nth-child(3n){
                    margin-right: 13px;
                   }
                   &:nth-child(2n){
                    margin-right: 0;
                }
                }
                @media all and (max-width:480px){
                    width:47%;
                    
                   &:nth-child(3n){
                    margin-right: 13px;
                   }
                   &:nth-child(2n){
                    margin-right: 0;
                }
                }
                }

            }
        }
    }
}