div.liveplayerContainer {
  position: relative;
  padding: 100px 0 0;
  margin-bottom: 100px;

  div.playerContainer {
    width: 90%;
    margin: 0 auto;
    background-color: var(--black-color);
    position: relative;

    @media all and (min-width:768px) {
      &:hover {
        div.bottom {
          div.details {
            transform: translateY(0);

          }
        }
      }
    }
    
    @media all and (min-width:1024px){  
      width: 800px;  
      div.video-js{
        padding-top: 0 !important;
        height: 520px !important;
      } 

    }
    @media all and (min-width:1440px){  
      width: 1089px;  
      div.video-js{
        padding-top: 0 !important;
        height: 612px !important;
      } 

    }

    div.bottom {
      width: 90%;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      height: 200px;
      overflow: hidden;

      @media all and (max-width:768px) {
        position: relative;
        bottom: unset;
        left: unset;
        transform: unset;
        width: 100%;
        height: auto;
        background: var(--bg-color);
      }

      div.details {
        display: flex;
        justify-content: space-between;
        height: 100%;
        transform: translateY(200px);
        transition: 0.3s ease-in-out;
        background-color: rgba(41, 36, 35, 0.6);
        padding: 20px;
        border-radius: 8px;

        @media all and (max-width:768px) {
          flex-direction: column;
          transform: unset;
        }


        h1.heading {
          font-size: 28px;
          color: var(--text-color);
          margin-bottom: 10px;

          @media all and (max-width:768px) {
            font-size: 22px;
          }
        }

        h1.title {
          font-size: 20px;
          color: var(--text-hover);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          //   width: 100%;

        }

        span.time {
          font-size: 14px;
          color: var(--inner-text-color);
          display: inline-block;
          margin-bottom: 10px;
        }

        p.description {
          color: var(--text-color);
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        div.left {
          width: 100%;
          height: 100%;

          @media all and (max-width:768px) {
            width: 100%;
            margin-bottom: 20px;
          }

          div.HeadingButtonContainer {
            display: flex;
            justify-content: space-between;
            div.buttonContainer {
              display: flex;
              @media all and (max-width:768px){
                display: none;
              }
              @media all and (max-width:980px) {
                justify-content: flex-start;
                margin-top: 5px;
              }

              button {
                background-color: var(--button-color);
                color: var(--white-color);
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
                border: 1px solid var(--button-color);
                transition: 0.4s ease-in-out;
                @media all and (max-width:980px) {
                  margin-bottom: 4px;}
                &:hover {
                  background-color: transparent;
                }

                &.myList {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  video {
    height: 100% !important;
    display: block !important;
    width: 100% !important;
    outline: rgb(0, 0, 0) !important;
  }

  div.videoMetaData {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;

    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;

      @media all and (max-width:980px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media all and (max-width:480px) {
        margin-top: 20px;
      }

      h1.videoTitle {
        color: var(--text-color);
        font-size: 30px;
      }

      div.buttonContainer {
        display: flex;
        @media all and (min-width:769px) {
          display: none;
        }
        @media all and (max-width:980px) {
          justify-content: flex-start;
          margin-top: 10px;
        }

        button {
          background-color: var(--button-color);
          color: var(--white-color);
          font-weight: 600;
          padding: 10px 20px;
          border-radius: 6px;
          font-size: 14px;
          cursor: pointer;
          border: 1px solid var(--button-color);
          transition: 0.4s ease-in-out;

          &:hover {
            background-color: transparent;
          }

          &.myList {
            margin-right: 20px;
          }
        }
      }
    }

    p.description {
      color: var(--inner-text-color);
      margin-bottom: 20px;
    }
  }

  div.upNextContainer {
    width: 90%;
    margin: 0 auto;

    h1.heading {
      text-align: left;
      color: var(--heading-color);
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-wrap: wrap;
      cursor: pointer;

      @media all and (max-width:980px) {
        width: 100%;
      }

      @media all and (max-width:768px) {
        font-size: 24px;
      }
    }

    div.upNext {
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-wrap: wrap;
      margin-top: 20px;

      @media all and (max-width:980px) {
        width: 100%;
      }

      div.item {
        width: 32.5%;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }

        @media all and (max-width:1200px) {
          width: 32%;
        }

        @media all and (max-width:768px) {
          width: 48.5%;
          margin-bottom: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media all and (max-width:480px) {
          width: 100%;
          margin-right: 0;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }

}