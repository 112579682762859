div.featuredBannerType2 { 
position: relative;
margin-top: 97px;
min-height: 472px;
overflow: hidden;
@media all and (max-width:1400px){
    min-height: 472px;
}
@media all and (max-width:1200px){
    min-height: 342px;
}
@media all and (max-width:980px){
    min-height: 457px;
}
@media all and (max-width:480px){
    min-height: 400px;
}
div.loading{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
}
// &::after{
//     content: "Loading...";
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     top: 0;
//     // background-color: rgba(000,000,000,1);
//     background-image: url(./assets/Images/loading-landscape.gif);
//     background-repeat: no-repeat;
//     background-size: 100%;
//     // transform: translateX(-100%);
//     // background-image: linear-gradient(
//     //   90deg,
//     //   rgba(#fff, 0) 0,
//     //   rgba(#fff, 0.2) 20%,
//     //   rgba(#fff, 0.5) 60%,
//     //   rgba(#fff, 0),rgba(000,000,000,1)
//     // );
//     // animation: shimmer 3s infinite;
//     z-index: 9;
//     min-height: 441px;
//     color: red;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

    .swiper .swiper-slide-active h1.title{
        animation: slideLeft 0.5s ease-in-out;

    }
    .swiper .swiper-slide-active p.description{
        animation: slideLeft 0.5s ease-in-out;

    }
    .swiper .swiper-slide-active span.duration{
        animation: slideLeft 0.5s ease-in-out;

    }
    .swiper .swiper-slide-active div.buttonContainer{
        animation: slideLeft 0.7s ease-in-out;

    }
    &:hover{
        .swiper-button-next,.swiper-button-prev{
            opacity: 1;
        }
       
    }
    
    .swiper-button-next{
        opacity: 0;
        right: 0 !important;
        transform: translateY(-50%);
        width:60px !important;
        background: rgb(0,0,0);
        background: linear-gradient(281deg, rgba(0,0,0,0.18539915966386555) 0%, rgba(0,0,0,0.560749299719888) 31%, rgba(0,0,0,0) 91%, rgba(105,105,105,0.09576330532212884) 100%, rgba(0,0,0,0) 100%);
        @media all and (max-width:980px){
            display: none;
        }
    }
    .swiper-button-next::after{
        background-color: transparent !important;
        color: #fff;
        font-size: 25px !important;
    }
    .swiper-button-prev{
        opacity: 0;

        left: 0 !important;
        transform: translateY(-50%);
        width:60px !important;
        @media all and (max-width:980px){
           display: none;
        }
    }
    .swiper-button-prev::after{
        background: rgb(0,0,0);
        background: linear-gradient(281deg, rgba(0,0,0,0.18539915966386555) 0%, rgba(0,0,0,0.560749299719888) 31%, rgba(0,0,0,0) 91%, rgba(105,105,105,0.09576330532212884) 100%, rgba(0,0,0,0) 100%);
        color: #fff;
        font-size: 25px !important;
    }
		div.bannerContainer { 
            display: flex;
            align-items: center;
            background-color: var(--black-color);
            justify-content: space-between;
            animation: fadeIn 0.4s ease-in-out;
            // &.loading{
                // &::after{
                //     content: "";
                //     position: absolute;
                //     left: 0;
                //     bottom: 0;
                //     right: 0;
                //     top: 0;
                //     transform: translateX(-100%);
                //     background-image: linear-gradient(
                //       90deg,
                //       rgba(#fff, 0) 0,
                //       rgba(#fff, 0.2) 20%,
                //       rgba(#fff, 0.5) 60%,
                //       rgba(#fff, 0)
                //     );
                //     animation: shimmer 3s infinite;
                //     z-index: 999;
                //     min-height: 581px;
                // }
              
            // }
            @media all and (max-width:980px){
                flex-direction: column-reverse;
             }
			div.left { 
                width: 35%;
                height: 100%;
                padding-left: 60px;
                padding-top: 30px;
                z-index: 9;
                @media all and (max-width:980px){
                    width: 100%;
                 }
                 @media all and (max-width:768px){
                    padding-left: 30px;
                    padding-right: 30px;
                }
                @media all and (max-width:480px){
                    padding-top: 100px;
                 }
                 @media all and (max-width:480px){
                    padding-top: 105px;
                 }
                div.contents{
                    position: absolute;
                    width: 40%;
                    transform: translateY(-50%);
                    top: 50%;
                    @media all and (max-width:1280px){
                        width: 45%;
                    }
                    @media all and (max-width:980px){
                       position: relative;
                       width: 100%;
                    }
                }
				h1.title{
                    font-size: 40px;
                    color: var(--text-color);
                    margin-bottom: 10px;
                    @media all and (max-width:1300px){
                       font-size: 36px;
                      }
                      @media all and (max-width:480px){
                        font-size: 27px;
                       }
                }
                span.duration{
                    color: var(--inner-text-color);
                    display: inline-block;
                    margin-bottom: 15px;
                }

				p.description { 
                    color: var(--text-color);
                    margin-bottom: 20px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box; 
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; 
                    @media all and (max-width:640px){
                        display: none;
                    }
				}
                div.buttonsWrapper{
                    display: flex;
                }
				div.buttonContainer { 
                    display: flex;
                padding: 18px 20px;
                border-radius: 4px;
                align-items: center;
                margin-bottom: 15px;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                justify-content: center;
                position: relative;
                width: 12rem;
                margin-right: 10px;
                @media all and (max-width:1280px){
                    width: 11rem;
                    padding: 13px 19px;
                }
                @media all and (max-width:480px){
                    width: 8rem;
                    padding: 9px 19px;
                   }
                &:hover{
                    div.background{

                        transform: scale(1.0476190476,1.0357142857);
                    }
                }
                    div.background{
                        background: var(--button-color);
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 4px;
                        transition: 0.1s ease-in-out;

                    }
                    div.Trailerbackground{
                        background: var(--secondary-bg-color);
                    
                    }
					button { 
                        background: transparent;
                        font-size: 15px;
                        color: var( --text-color);
                        font-weight: 600;
                        z-index: 1;
                        cursor: pointer;

					}
				}
			}

			div.right { 
                width: 62%;
                @media all and (max-width:980px){
                    width: 100%;
                 }
                div.fadeGradient{
                    position: absolute;
                    left: 27%;
                    width: 30%;
                    height: 100%;
                    background: linear-gradient(89deg, rgba(0,0,0,1) 45%, rgba(0,0,0,0.6083683473389356) 68%, rgba(0,0,0,0) 91%, rgba(0,0,0,0) 100%, rgba(105,105,105,0.09576330532212884) 100%);
                    @media all and (max-width:980px){
                        left: 0;
                        width: 100%;
                        background: linear-gradient(0deg, rgb(0, 0, 0) 45%, rgba(0, 0, 0, 0.6083683473) 68%, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0) 100%, rgba(105, 105, 105, 0.0957633053) 100%);
                        bottom: 143px;
                        height: 31%;
                    }
                    @media all and (max-width:768px){
                        height: 38%;
                    }
                    @media all and (max-width:480px){
                        height: 38%;
                        bottom: 74px;
                    }
                }
				div.imageContainer { 
                    width: 100%;
                    &.loading{
                        height: 600px;
                        background-color: var(--black-color);
                        
                    }
					img { 
                        width: 100%;
                        display: block;

					}
				}
			}
		}
}
// swiper-slide-active
@keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
@keyframes slideLeft {
    0%{
        transform: translateX(43px);
    }
    100%{
        transform: translateX(0);
    }
}
