div.magazineCardContainer{
    width: 100%;
    cursor: pointer;
    position: relative;
    @media all and (max-width: 980px) {
      width: 100%;
    }
    
    &.noPointer{
      cursor: default;
    }
    position: relative;
    div.showMoreOverlay{
      display: none;
    }
    &.cardHover{
      cursor: pointer;
    &:hover{
      .square{
        visibility: visible;
        div.metaData{
          display: block;
          visibility: visible !important;
          animation: fadeIn 0.3s ease-in-out;
        }
      }
    }
    &:not(:hover) {
      .square {
        transform: none; 
      }
      div.metaData {
        display: none; 
        visibility: hidden;
      }
    }
  }
  
  
    .square {
      width: 100%;  
      transition: transform 500ms ease-out, border-radius 200ms ease-out;
      &.hide{
        div.metaData{
          display: none;
          @media all and (max-width:980px){
            display: block;
          }
        }
      }
        div.metaData{
          display: none;
          &.hovered {
            display: block;
            visibility: visible !important;
            animation: fadeIn 0.3s ease-in-out;
          }
          @media all and (max-width:980px){
            display: block;
          }
  
        }
      div.imageContainer {
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
       box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        img {
          width: 100%;
          display: block;
          transition: 0.2s ease-in-out;
          border-top-right-radius: 3px;
          border-top-left-radius: 3px;
          &:hover{
            transform: scale(1.1);
          }
        }  
        div.extraActions {
          visibility: hidden;
    
          div.showCardOverlay {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: var(--overlay);
            z-index: 8;
          } 
        }
      }
     
      div.metaData {
        background-color: var(--bg-color);
        color: #fff;
        width: 100%;
        height: fit-content;
        box-sizing: border-box;
        transition: opacity 300ms ease-out, border-radius 200ms ease-out;
        visibility: visible;
        transition: 0.9s ease-in-out;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
       
        h1.title{
          color: var(--text-color);
          font-size: 16px;
          transition: 0.3s ease-in-out;
          overflow: hidden;
          text-overflow: ellipsis;
         
          display: -webkit-box;
          -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &:hover{
         color: var(--text-hover);
        }
        @media all and (min-width: 1200px) {
          font-size: 14px;
        }
        @media all and (min-width: 980px) {
          font-size: 11px;
        }
        
          @media all and (min-width: 320px) {
            font-size: 12px;
          }
          @media all and (min-width: 640px) {
            font-size: 14px;
          }
          &:hover{
            color: var(--text-hover);
          }
        }
        
        div.buttons{
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          margin-top: 10px;
       
  
          span.material-symbols-outlined{
            font-weight: 300 !important;
            font-size: 20px !important;
  
          }
          svg{
            width: 100% !important;
            height: 15px !important;
  
          }
          button{
            color:var( --white-color);
            background-color: var(--button-color);
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            &.play{
              background-color: var(--white-color);
              margin-right: 4px;
              transition: 0.2s ease-in-out;
            
            
            }
            &:hover{
              background-color:var( --disabled-btn-color);
            }
          }
          div.left{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  
  
  
    
  }
  
  