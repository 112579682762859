div.categoriesContainer {
  padding: 15px 0;
  border-bottom: 1px solid var(--border-color);
  &:hover {
    .swiper-button-next {
      &::after {
        opacity: 1;
      }
    }
    .swiper-button-prev {
      &::after {
        opacity: 1;
      }
    }
  }
  .swiper {
    &.landscape {
        .swiper-button-next {
            right: -82px;
            height: 100%;
            width: 6%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            // 
            @media all and (min-width:320px){
                display: none;
                
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                right: -47px;
                top: 8%;
            }
            @media all and (min-width:1200px){
              right: -57px;
            width: 4% !important;
              top: 7%;

          }
          @media all and (min-width:1400px){
            top: 6%;

            right: -67px;
        }
        @media all and (min-width:1600px){
          right: -70px;
        }
        @media all and (min-width:1768px){
          right: -79px;
          top: 5%;
        }
        @media all and (min-width:1980px){
            right: -91px;

          }
          @media all and (min-width:2200px){
            right: -99px;
        }
            // @media all and (min-width:768px){
            //    width: 7%;
            // }
          }
          .swiper-button-prev {
            left: -77px;
            height: 100%;
            width: 5%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            
            @media all and (min-width:320px){
                display: none;
        
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                left: -47px;
                top: 8%;
            }
            @media all and (min-width:1200px){
              left: -57px;
              width: 4% !important;
              top: 7%;
          }
          @media all and (min-width:1400px){
            left: -67px;
            top: 6%;

        }
        @media all and (min-width:1600px){
          left: -70px;
        }
        @media all and (min-width:1768px){
          left: -79px;
          top: 5%;
        }
        @media all and (min-width:1980px){
            left: -91px;
        }
        @media all and (min-width:2200px){
            left: -99px;
        }
            // @media all and (min-width:768px){
            //     width: 7%;
            //  }
          }
          div.swiper-slide{
            position: relative;
            div.showAll{
              display: none;
            }
            &:last-child{

              div.showAll{
                display: flex;
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  top: 0;
                  background-color:var(--secondary-bg-color);
                  z-index: 99999;
                  align-items: center;
                  justify-content: center;
                  border-radius: 3px;
                  cursor: pointer;
                  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                  &:hover{
                    h1{
                      text-decoration: underline;
                      transform: scale(1.1);

                    }
                  }
                  h1{
                    font-size: 20px;
                  font-weight: 600;
                  color: var(--text-color);
                  transition: 0.3s ease-in-out;
                  }
                  p{
                    text-align: center ;
                  }
              }
              div.show.lastChild{
                div.showCardContainer{
                  div.metaData{
                    display: none;
                  }
                }
              }
            }
          }
    }
    &.portrait{

        // .swiper-button-next {
        //   &::after {
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--inner-text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
            
        //   }
        // }
        // .swiper-button-prev {
        //   &::after {
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--inner-text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
           
        //   }
        // }

        .swiper-button-next {
            right: -82px;
            height: 100%;
            width: 6%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            // 
            @media all and (min-width:320px){
                display: none;
                
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                right: -47px;
                top: 6%;
            }
            @media all and (min-width:1200px){
              right: -57px;
            width: 4% !important;
              top: 5%;

          }
          @media all and (min-width:1400px){

            right: -67px;
        }
        @media all and (min-width:1600px){
          right: -70px;
        }
        @media all and (min-width:1768px){
          right: -79px;
          top: 4%;
        }
        @media all and (min-width:1980px){
            right: -91px;

          }
          @media all and (min-width:2200px){
            right: -99px;
        }
            // @media all and (min-width:768px){
            //    width: 7%;
            // }
          }
          .swiper-button-prev {
            left: -77px;
            height: 100%;
            width: 5%;
            top: 5%;
            background: linear-gradient(
              -97deg,
              rgb(28, 31, 41) 0.98%,
              rgba(28, 31, 41, 0.4) 100.23%,
              rgba(28, 31, 41, 0.2) 100.16%,
              rgba(28, 31, 41, 0.0001) 52.12%
            );
            
            @media all and (min-width:320px){
                display: none;
        
            }
            @media all and (min-width:980px){
                display: flex;
                width: 4%;
                left: -47px;
                top: 5%;
            }
            @media all and (min-width:1200px){
              left: -57px;
              width: 4% !important;
              top: 5%;
          }
          @media all and (min-width:1400px){
            left: -67px;

        }
        @media all and (min-width:1600px){
          left: -70px;
        }
        @media all and (min-width:1768px){
          left: -79px;
          top: 4%;
        }
        @media all and (min-width:1980px){
            left: -91px;
        }
        @media all and (min-width:2200px){
            left: -99px;
        }
            // @media all and (min-width:768px){
            //     width: 7%;
            //  }
          }
          div.swiper-slide{
            position: relative;
            div.showAll{
              display: none;
            }
            
          }
          .magazine{
            width: 250px !important;
            @media all and (max-width:2600px){
              width: 400px !important;
            }
            @media all and (max-width:2500px){
              width: 350px !important;
            }
            @media all and (max-width:1980px){
              width: 300px !important;
            }
            @media all and (max-width:1600px){
              width: 230px !important;
            }
            @media all and (max-width:1400px){
              width: 220px !important;
            }
            @media all and (max-width:980px){
              width: 180px !important;
            }
            @media all and (max-width:768px){
              width: 165px !important;
            }
            @media all and (max-width:360px){
              width: 155px !important;
            }
          }
    }
  }
  div.categoryNameContainer {
    display: inline;
    // align-items: center;
    cursor: pointer;
    width: 100%;
    &:hover {
      h1 {
        color: var(--text-hover);
      }
      svg {
        color: var(--text-hover);
      }
    }
    h1 {
      font-size: 24px;
      margin-right: 20px;
      color: var(--text-color);
      transition: 0.3s ease-in-out;
      display: inline-block;
    }
    svg {
      color: var(--text-color);
      height: 20px !important;
      width: 20px !important;
      transition: 0.3s ease-in-out;
      vertical-align: sub;
    }
  }
  div.itemsContainer {
    margin-top: 20px;
    div.show {
      width: 14rem;
      min-height: 130px;
      &:last-child{
        position: relative;
      }
      @media all and (min-width: 320px) {
        width: 100%;
      }
     
    }
    div.magazinecard{ 
      background-color:var(--overlay);
      border-radius: 5%;     
    }

  }
}
