div.subscriptionList { 
    padding: 100px 0;
    h1.heading { 
        font-size: 28px;
        text-align: center;
        color: var(--inner-text-color);
    
    }
    div.subscriptionDetails { 
        border: 1px solid var(--border-color);
        box-shadow: rgba(229, 9, 20, 0.25) 0px 13px 27px -5px, rgba(229, 9, 20, 0.3) 0px 8px 0px -47px;
                padding: 15px 28px;
        margin-top: 50px;
        color: var(--inner-text-color);

        h2.heading { 
            font-size: 22px;
            color: var(--inner-text-color);
        
            text-align: center;
        }
        ul.conditions { 
            list-style: square;
            margin-top: 30px;

            li { 
                line-height: 28px;

                span { 
                    font-size: 16px;
                }
            }
        }
    
    }
    div.pricing { 
        margin-top: 30px;

        ul { 
            display: flex;
            list-style: none;
            flex-wrap: wrap;

            li { 
                margin-right: 20px;
                margin-bottom: 30px;
                padding: 60px 60px;
                border: 1px solid var(--border-color);
                width: 23.8%;
                text-align: center;
                box-shadow: rgba(229, 9, 20, 0.24) 0px 3px 8px;
                &:last-child{
                    margin-right: 0;
                }
                &:nth-child(4n){
                    margin-right: 0;
                }
                @media all and (max-width:1400px){
                    width: 23.5%;
                }
                @media all and (max-width:1200px){
                    width: 31.5%;
                    &:nth-child(4n){
                        margin-right: 20px;
                    }
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                }
                @media all and (max-width:980px){
                    width: 48%;
                    &:nth-child(3n){
                        margin-right: 20px;
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
                @media all and (max-width:640px){
                    width: 100%;
                    margin-right: 0;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
                button { 
                    display: block;
                    border: none;
                    outline: none;
                    padding: 10px 25px;
                    background-color: var(--secondary-bg-color);
                    border: 1px solid var(--border-color);
                    margin: 0 auto 20px;
                    color: #fff;
                    border-radius: 30px;
                    transition: 0.5s all ease-in-out;
                    cursor: pointer;
                    &:hover { 
                        background-color: transparent;
                       
                    }
                }
                span.category { 
                    display: block;
                    margin-bottom: 10px;
                    color: var(--inner-text-color);
                }
                span.price { 
                    display: block;
                    color: var(--inner-text-color);
                    &.discount{
                        span.priceCut{
                            margin-right: 10px;
                            text-decoration: line-through;
                            display: block;
                        }
                    }
                    
                    }
            }
        }
    }
}

/* div.subscriptionList div.subscriptionDetails ul.conditions li::before { 
    content: "-";
    margin-right: 4px;
} */

@media all and (max-width:1200px){
    div.subscriptionList div.subscriptionDetails h2.heading {
        font-size: 20px;
    }
    div.subscriptionList div.subscriptionDetails ul.conditions li {
        font-size: 15px;
    }
}
@media all and (max-width:980px){
    div.subscriptionList div.subscriptionDetails h2.heading {
        font-size: 19px;
    }
}
@media all and (max-width:768px){
    div.subscriptionList div.subscriptionDetails h2.heading {
        font-size: 18px;
    }
}
@media all and (max-width:640px){
    div.subscriptionList div.pricing ul {
        flex-direction: column;
    }
    // div.subscriptionList div.pricing ul li {
    //     margin-right: 0;
    //     width: 80%;
    //     margin: 0 auto 20px;
    // }
    div.subscriptionList div.pricing ul li:last-child {
     
        margin-bottom: 0;
    }
    div.subscriptionList div.pricing ul li button {
        margin: 0 auto 20px;
    }
}
@media all and (max-width:480px){
    div.subscriptionList div.pricing ul li {
        width: 100%;
    }
    div.subscriptionList h1.heading {
        font-size: 25px;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}