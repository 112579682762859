div.detailsScreen {
  // scroll-behavior: smooth;
  padding: 100px 0 0;
  position: relative;
  .swiper-button-next {
    right: -9px !important;
    top: 45%;
    @media all and (min-width: 1200px) {
      right: 0 !important;
    }
  }
  .swiper-button-prev {
    left: -9px !important;
    top: 45%;
    @media all and (min-width: 1200px) {
      left: 0 !important;
    }
  }
  div.wrapper {
    background-color: var(--bg-color);
    position: relative;
    z-index: 8;
  }
  div.bgImageContainer {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 7;
    width: 100%;
    div.gradient {
      position: absolute;
      bottom: 0;
      z-index: 8;
      width: 100%;
      height: 100%;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      // background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
      // background: linear-gradient(78.83deg,#10141f,rgba(16,20,31,0) 61.46%),linear-gradient(1turn,#10141f,rgba(16,20,31,0) 48.44%,rgba(16,20,31,.4));
      background: linear-gradient(
          78.83deg,
          #10141f,
          rgba(16, 20, 31, 0) 260.46%
        ),
        linear-gradient(
          1turn,
          #10141f,
          rgba(16, 20, 31, 0) 48.44%,
          rgba(16, 20, 31, 0.4)
        ),
        rgba(16, 20, 31, 0.4);
    }
    img {
      width: 100%;
      display: block;
    }
  }
  div.videoContainer {
    background-color: #000;
    width: 100%;
    // animation: fadeIn 0.5s ease-in-out;
    &.heightIncrease {
      height: 38em;
      @media all and (max-width: 1200px) {
        height: 25em;
      }
    }
    div.gradientTop {
      position: absolute;
      top: 0;
      z-index: 8;
      width: 100%;
      height: 20%;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      //   background: linear-gradient(
      //       179.83deg,
      //       rgb(16, 20, 31) 0%,
      //       rgba(16, 20, 31, 0) 64.46%
      //     ),
      //     linear-gradient(
      //       540deg,
      //       rgb(16, 20, 31) 0%,
      //       rgba(16, 20, 31, 0) 100.44%,
      //       rgba(16, 20, 31, 0.4) -26%
      //     );
      background: linear-gradient(
        0deg,
        rgba(16, 20, 31, 0),
        rgba(16, 20, 31, 0.2) 19%,
        rgba(16, 20, 31, 0.4) 39%,
        rgba(16, 20, 31, 0.8)
      );
    }
    div.player {
      position: relative;
      z-index: 9;
      width: 100%;
      display: block;
      div.closeIcon {
        display: none;
      }
      div.nowPlaying{
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 7;
        h1{
          color: var(--text-color);
          @media all and (max-width:1200px){
            font-size: 23px;
          }
          @media all and (max-width:980px){
            font-size: 20px;
          }
          @media all and (max-width:768px){
            font-size: 18px;
          }
          @media all and (max-width:640px){
            font-size: 16px;
          }
          @media all and (max-width:480px){
            font-size: 14px;
          }
        }
      }
      &.pipMode {
        position: fixed;
        top: 110px;
        width: 22%;
        right: 0;
        z-index: 999999999;
        height: 200px;

        @media all and (max-width: 768px) {
          width: 40%;
        }
        @media all and (max-width: 640px) {
          width: 50%;
        }
        @media all and (max-width: 480px) {
          width: 68%;
        }
        video {
          object-fit: cover;
          height: 100%;
        }
        .video-js {
          padding: 0;
          height: 100%;
        }
        div.gradientTop {
          display: none;
        }
        div.nowPlaying{
          display: none;
        }
        &:hover {
          div.closeIcon {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 9;
            background-color: var(--bg-color);
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            svg {
              color: var(--text-color);
            }
          }
          div.gradientTop {
            display: block;
          }
        }
      }
    }

    video {
      display: block;
      width: 100%;
    }
  }
  div.details {
    position: relative;
    z-index: 8;
    display: flex;
    padding: 30px 0 130px;
    @media all and (max-width: 980px) {
      flex-direction: column;
    }
    div.watchNow {
      width: 100%;
      margin-top: 15px;
      button {
        width: 100%;
        display: block;
        background: var(--button-color);
        padding: 15px 10px;
        font-size: 15px;
        color: var(--text-color);
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
          background: var(--button-hover);
        }
      }
    }
    div.trailer {
      width: 100%;
      margin-top: 15px;
      button {
        width: 100%;
        display: block;
        background-color: rgba(150, 153, 163, 0.16);
        padding: 15px 10px;
        font-size: 15px;
        color: var(--text-color);
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
      }
    }
    div.addtoList {
      width: 100%;
      margin-top: 15px;
      button {
        width: 100%;
        display: block;
        background-color: rgba(150, 153, 163, 0.16);
        padding: 15px 10px;
        font-size: 15px;
        color: var(--text-color);
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: rgba(203, 203, 208, 0.16);
        }
      }
    }

    div.buttons {
      margin-top: 15px;
      display: flex;
      width: 100%;
      div.buttonContainerOne {
        width: 70%;
        position: relative;
        margin-right: 10px;
        @media all and (max-width: 980px) {
          width: 90%;
        }
        @media all and (max-width: 640px) {
          width: 80%;
        }
        @media all and (max-width: 480px) {
          width: 75%;
        }
        div.shareContainer {
          position: absolute;
          bottom: -126px;
          left: -20px;
          z-index: 9;
          background-color: #1c1f29;
          border: 1px solid var(--border-color);
          width: 232px;
          border-radius: 8px;
          display: none;
          @media all and (max-width: 1200px) {
            width: 200px;
          }
          @media all and (max-width: 980px) {
            width: 232px;
            left: 50%;
            transform: translateX(-50%);
          }
          @media all and (max-width: 480px) {
            width: 100%;
          }
          &.active {
            display: block;
          }
          &::after {
            content: "";
            top: -9px;
            width: 0;
            height: 0;
            border-color: transparent transparent rgba(240, 241, 245, 0.16);
            border-style: solid;
            border-width: 0 8px 8px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            @media all and (max-width: 1200px) {
              left: 40%;
            }
            @media all and (max-width: 980px) {
              left: 50%;
            }
          }
          div.facebookContainer {
            // display: flex;
            // justify-content: space-between;
            // padding: 15px;
            // color: var(--text-color);
            // cursor: pointer;
            // border-bottom: 1px solid var(--border-color);
            button.facebook {
              display: flex !important;
              justify-content: space-between !important;
              padding: 15px !important;
              color: var(--text-color) !important;
              cursor: pointer !important;
              border-bottom: 1px solid var(--border-color) !important;
              width: 100% !important;
              div.icon {
                width: 10%;
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
            div.left {
              width: 10%;
            }
            div.right {
              svg {
                width: 20px !important;
                height: 20px !important;
                color: var(--grey-color);
                transition: 0.3s ease-in-out;
                &:hover {
                  color: var(--text-color);
                }
              }
            }
          }
          div.twitterContainer {
            // display: flex;
            // justify-content: space-between;
            // padding: 15px;
            // color: var(--text-color);

            // cursor: pointer;
            button.twitter {
              display: flex !important;
              justify-content: space-between !important;
              padding: 15px !important;
              color: var(--text-color) !important;
              cursor: pointer !important;
              // border-bottom: 1px solid var(--border-color) !important;
              width: 100% !important;
              div.icon {
                width: 10%;
                svg {
                  width: 20px !important;
                  height: 20px !important;
                  color: var(--grey-color);
                  transition: 0.3s ease-in-out;
                  &:hover {
                    color: var(--text-color);
                  }
                }
              }
            }
            div.left {
              width: 10%;
            }
            div.right {
              svg {
                width: 20px !important;
                height: 20px !important;
                color: var(--grey-color);
                transition: 0.3s ease-in-out;
                &:hover {
                  color: var(--text-color);
                }
              }
            }
          }
        }
        button.share {
          display: block;
          background-color: rgba(150, 153, 163, 0.16);
          padding: 15px 10px;
          font-size: 15px;
          color: var(--text-color);
          font-weight: 600;
          border-radius: 8px;
          width: 100%;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          &:hover {
            background-color: rgba(203, 203, 208, 0.16);
          }
        }
      }
      div.buttonContainerTwo {
        width: 30%;
        position: relative;
        @media all and (max-width: 980px) {
          width: 10%;
        }
        @media all and (max-width: 640px) {
          width: 18%;
        }
        @media all and (max-width: 480px) {
          width: 25%;
        }
        button.more {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(150, 153, 163, 0.16);
          padding: 15px 10px;
          font-size: 15px;
          color: var(--text-color);
          border-radius: 8px;
          width: 100%;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          svg {
            width: 20px !important;
            height: 20px !important;
          }
          &:hover {
            background-color: rgba(203, 203, 208, 0.16);
          }
        }
        div.reportProblemContainer {
          position: absolute;
          bottom: -66px;
          right: 0;
          z-index: 9;
          background-color: #1c1f29;
          border: 1px solid var(--border-color);
          width: 200px;
          border-radius: 8px;
          padding: 15px;
          display: none;
          cursor: pointer;
          &.active {
            display: block;
          }
          @media all and (max-width: 1200px) {
            width: 164px;
          }
          @media all and (max-width: 980px) {
            width: 200px;
          }

          span {
            color: var(--text-color);
          }
          &::after {
            content: "";
            top: -9px;
            width: 0;
            height: 0;
            border-color: transparent transparent rgba(240, 241, 245, 0.16);
            border-style: solid;
            border-width: 0 8px 8px;
            position: absolute;
            right: 8%;
            transform: translateX(-50%);
            @media all and (max-width: 1200px) {
              right: 5%;
            }
            @media all and (max-width: 980px) {
              right: 8%;
            }
          }
        }
      }
    }
    div.subscriptions {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      div.items {
        cursor: pointer;
        // width: 8rem;
        margin-right: 10px;
        background-color: var(--yellow-color);
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        color: var(--text-color);
        margin-bottom: 20px;
        border-radius: 30px;
        &.free {
          background-color: var(--success-color);
        }
      }
    }
    div.leftContainer {
      width: 27%;
      margin-right: 40px;
      @media all and (max-width: 980px) {
        display: none;
      }
      div.showCardDetails {
        width: 100%;
        position: sticky;
        top: 0;
        div.imageContainer {
          width: 100%;
          img {
            width: 100%;
            display: block;
            border-radius: 5px;
          }
        }
      }
    }

    div.rightContainer {
      width: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      div.showMetadata {
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 40px;
        width: 100%;
        h1.name {
          font-size: 58px;
          font-weight: 700;
          color: var(--text-color);
          @media all and (max-width: 1200px) {
            font-size: 48px;
          }
          @media all and (max-width: 640px) {
            font-size: 35px;
          }
        }
       

        div.data {
          display: flex;
          margin-bottom: 6px;
          flex-wrap: wrap;
          align-items: center;
          span {
            font-size: 18px;
            color: var(--inner-text-color);
            @media all and (max-width: 640px) {
              font-size: 14px;
            }
            &.year {
              margin-right: 6px;
            }
            &.duration {
              margin-right: 8px;
              &.showDot{

                &::before {
                  content: "·";
                  margin-right: 6px;
                }
              }
            }
            &.certificate {
              display: block;
              padding: 4px 6px;
              background-color: rgba(150, 153, 163, 0.32);
              font-size: 11px;
              font-weight: 700;
              color: var(--text-color);
              border-radius: 4px;
              @media all and (max-width: 640px) {
                font-size: 10px;
              }
            }
          }
          div.userRatings{
            display: flex;
            margin-left: 15px;
            @media all and (max-width:360px){
              // margin-top: 10px;
              margin-left: 10px;
            }
            div.star{
              &.active{
                svg{
                  fill: #fbe725;
                }
              }
              svg {
                width: 23px !important;
                height: 23px !important;
                fill: var(--grey-color);
                transition: 0.3s ease-in-out;
              }
            }
           
          }
          div.addRating{
            margin-left: 15px;

            cursor: pointer;
            &:hover{
              svg{
                fill: var(--grey-color);
              }
            }
            svg {
              width: 27px !important;
              height: 27px !important;
              fill: var(--white-color);
              transition: 0.3s ease-in-out;
            }
          }
        }

        div.genres {
          margin-bottom: 10px;
          span {
            font-size: 18px;
            color: var(--inner-text-color);
            margin-right: 6px;
            @media all and (max-width: 640px) {
              font-size: 14px;
            }
            &::before {
              content: "·";
              margin-right: 6px;
            }
            &:first-child {
              &::before {
                display: none;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
        div.watchNow {
          display: none;
          @media all and (max-width: 980px) {
            display: block;
          }
        }
        div.trailer {
          display: none;
          @media all and (max-width: 980px) {
            display: block;
          }
        }
        div.addtoList {
          display: none;
          @media all and (max-width: 980px) {
            display: block;
          }
        }
        div.buttons {
          display: none;
          margin-bottom: 20px;
          @media all and (max-width: 980px) {
            display: flex;
          }
        }

        p.description {
          font-size: 15px;
          color: var(--text-color);
          line-height: 1.4em;
          letter-spacing: 0.3px;
          max-width: 100%;
          @media all and (max-width: 1200px) {
            max-width: 100%;
          }
        }

        div.info {
          margin-top: 20px;
          div.starring {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            div.left {
              width: 15%;
              @media all and (max-width: 768px) {
                width: 20%;
              }
              @media all and (max-width: 640px) {
                width: 25%;
              }
              @media all and (max-width: 480px) {
                width: 30%;
              }
              @media all and (max-width: 360px) {
                width: 33%;
              }

              h6 {
                font-size: 16px;
                font-weight: 400;
                color: var(--inner-text-color);
              }
            }

            div.right {
              width: 85%;
              @media all and (max-width: 980px) {
                width: 75%;
              }
              @media all and (max-width: 640px) {
                width: 70%;
              }
              @media all and (max-width: 480px) {
                width: 65%;
              }
              @media all and (max-width: 360px) {
                width: 55%;
              }
              ul.names {
                display: flex;
                flex-wrap: wrap;
                li {
                  font-size: 16px;
                  font-weight: 600;
                  margin-right: 8px;
                  color: var(--text-color);
                  &:last-child {
                    margin-right: 0;
                  }
                  @media all and (max-width: 480px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          div.director {
            display: flex;
            justify-content: space-between;
            div.left {
              width: 15%;
              @media all and (max-width: 768px) {
                width: 20%;
              }
              @media all and (max-width: 640px) {
                width: 25%;
              }
              @media all and (max-width: 480px) {
                width: 30%;
              }
              @media all and (max-width: 360px) {
                width: 33%;
              }

              h6 {
                font-size: 16px;
                font-weight: 400;
                color: var(--inner-text-color);
              }
            }

            div.right {
              width: 85%;
              @media all and (max-width: 980px) {
                width: 75%;
              }
              @media all and (max-width: 640px) {
                width: 70%;
              }
              @media all and (max-width: 480px) {
                width: 65%;
              }
              @media all and (max-width: 360px) {
                width: 55%;
              }
              h6 {
                font-size: 16px;
                font-weight: 600;
                margin-right: 8px;
                color: var(--text-color);
                @media all and (max-width: 480px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  div.parent {
    label {
    }

    div {
      a.fff {
      }
    }
  }
}
