div.header-container {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: linear-gradient(180deg,
      rgba(16, 20, 31, 0.8) 0.98%,
      rgba(16, 20, 31, 0.4) 59.23%,
      rgba(16, 20, 31, 0.2) 78.16%,
      rgba(16, 20, 31, 0.0001) 96.12%);
  height: 100px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  background: var(--bg-color);
  overflow: hidden;
  &.background {
    background: var(--bg-color);
    // animation: fadeIn 0.6s ease-in-out;
  }

  // &.hidden{
  //   top: -80px;
  //   transition:  0.4s ease-out;
  // }
  div.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 980px) {
      width: 97%;
    }

    div.leftContainer {
      display: flex;
      align-items: center;

      div.menuIconContainer {
        display: none;

        @media all and (max-width: 768px) {
          display: flex;
          margin-right: 10px;
          background-color: rgba(150, 153, 163, 0.16);
          padding: 8px 12px;
          border-radius: 6px;

          svg {
            width: 25px !important;
            height: 25px !important;
            color: #fff;
          }
        }
      }

      div.logoContainer {
        h1 {
          width: 120px;

          @media all and (max-width: 480px) {
            width: 72px;
          }

          @media all and (max-width: 360px) {
            width: 72px;
          }

          img {
            display: block;
            width: 100%;
          }
        }
      }

      ul.hide {
        @media all and (max-width: 1200px) {
          &.hide {
            display: none;
          }
        }
      }

      ul.browse {
        display: flex;
        align-items: center;

        li {
          cursor: pointer;
          padding: 0 30px;

          &:nth-child(2n) {
            padding: 0;
          }

          &.other-links {
            padding-bottom: 60px;
            margin-top: 60px;
            white-space: nowrap;

            &:hover {
              span {
                color: var(--text-hover);
              }
            }
          }

          &.active {
            padding-bottom: 60px;
            margin-top: 60px;

            span {
              color: var(--text-hover);
            }
          }

          span {
            font-size: 14px;
            font-weight: 900;
            transition: 0.3s ease-in-out;
            color: #fff;
          }
        }

        @media all and (max-width: 768px) {
          display: none;
        }
      }
    }

    div.rightContainer {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      justify-content: flex-end;
      width: 56%;
      transition: 0.2s ease-in-out;

      @media all and (max-width: 1400px) {
        width: 66%;
      }

      &.increase {
        width: 90%;
      }

      @media all and (max-width: 1200px) {
        &.increase {
          width: 90%;
        }
      }

      @media all and (max-width: 980px) {
        width: 78%;
      }

      div.inputContainer {
        border: 1px solid #9e9fa4;
        padding: 12px 15px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        width: 50%;
        justify-content: space-between;
        transition: 0.2s ease-in-out;
        margin-right: 30px;
        cursor: pointer;

        &.showInput {
          &.showInput {
            width: 40%;
          }
        }

        @media all and (max-width: 1400px) {
          width: 44%;
        }

        @media all and (max-width: 1200px) {
          width: 8%;

          &.showInput {
            width: 70%;

            input {
              display: block;
            }
          }
        }

        @media all and (max-width: 980px) {
          width: 12%;
          justify-content: center;
          padding: 10px 8px;
          margin-right: 18px;

          &.showInput {
            width: 57%;
            justify-content: space-between;
          }
        }

        @media all and (max-width: 768px) {
          display: none;
        }

        &:hover {
          border-color: #c5c5c8;

          input {
            &::placeholder {
              color: #fff;
            }
          }
        }

        svg {
          width: 25px !important;
          height: 25px !important;
          color: #fff !important;
          margin-right: 20px;

          @media all and (max-width: 1200px) {
            margin-right: 0;
          }
        }

        input {
          border: none;
          outline: none;
          width: 90%;
          background-color: transparent;
          font-size: 16px;
          color: #fff;

          @media all and (max-width: 1200px) {
            display: none;
          }

          &::placeholder {
            color: #9e9fa4;
            font-size: 16px;
            transition: 0.3s ease-in-out;
          }
        }
      }

      div.language {
        color: var(--white-color);
        cursor: pointer;

        @media all and (max-width: 768px) {
          display: none;
        }

        span {
          display: inline-block;
          margin-left: 6px;
          font-size: 16px;
          font-weight: 500;
        }
      }

      div.userInfo {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 40px;

        @media all and (max-width: 360px) {
          padding: 0 21px;
        }

        &.active {
          svg {
            transform: rotate(-180deg);
          }
        }

        h4 {
          color: var(--text-color);
          font-size: 14px;
          margin-right: 10px;

          &.active {
            padding-top: 60px;
            padding-bottom: 60px;
          }
        }

        svg {
          color: var(--text-color) !important;
          width: 22px !important;
          height: 22px !important;
          transition: 0.3s ease-in-out;
        }
      }

      div.buttonContainer {
        display: flex;
        margin-left: 30px;

        @media all and (max-width: 980px) {
          margin-left: 18px;
        }

        @media all and (max-width: 480px) {
          margin-left: 15px;
        }

        @media all and (max-width: 360px) {
          margin-left: 7px;
        }

        button.register {
          background-color: #fff;
          padding: 13px 0;
          width: 6rem;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 700;
          margin-right: 30px;
          cursor: pointer;

          @media all and (max-width: 768px) {
            padding: 11px 0;
            margin-right: 18px;
          }

          @media all and (max-width: 360px) {
            padding: 8px 0;
            width: 5rem;
            font-size: 12px;
            margin-right: 10px;
          }
        }

        button.signIn {
          background-color: transparent;
          font-weight: 700;
          font-size: 14px;
          color: #fff;
          cursor: pointer;

          @media all and (max-width: 360px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

div.userInfoContainer {
  position: fixed;
  right: 30px;
  width: 20rem;
  top: 15%;
  background-color: rgb(37, 42, 52);
  border-radius: 8px;

  display: none;
  z-index: 9999;

  &.active {
    display: block;
  }

  @media all and (max-width: 2600px) {
    top: 8%;
  }

  @media all and (max-width: 2200px) {
    top: 11%;
  }

  @media all and (max-width: 1768px) {
    top: 10%;
  }

  @media all and (max-width: 1600px) {
    top: 11%;
  }

  @media all and (max-width: 1500px) {
    top: 13%;
  }

  @media all and (max-width: 1400px) {
    top: 10%;
  }

  @media all and (max-width: 1200px) {
    top: 15%;
  }

  @media all and (max-width: 640px) {
    width: 84%;
  }

  div.top {
    // padding: 20px 20px 0;
    ul {
      a {
        li {
          padding: 15px 0;
          cursor: pointer;

          &:first-child {
            // padding-top: 0;
                padding-top: 10px;
          }

          &:last-child {
            border-bottom: none;
          }

          span {
            color: var(--text-color);
            font-weight: 600;
            font-size: 15px;
            margin-left: 20px;
            margin-top: 10px;
          }
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  div.bottom {
    background: linear-gradient(180deg, #10141f, rgba(16, 20, 31, 0));
    padding: 15px;

    div.signOutContainer {
      display: flex;
      align-items: center;
      padding: 12px;
      background-color: var(--border-color);
      border-radius: 8px;
      cursor: pointer;

      svg {
        color: var(--text-color);
        width: 22px !important;
        height: 22px !important;
        margin-right: 10px;
      }

      span {
        color: var(--text-color);
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}

div.overlayOne {
  &.active {
    @media all and (max-width: 1200px) {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--overlay);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.3s ease-in-out;
    }
  }
}

div.overlayOne.active {
  display: flex;
}

div.browseMore {
  @media all and (max-width: 1200px) {
    display: none;
  }

  //  animation: fadeIn 0.3s ease-in-out ;
  display: none;

  div.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }

  div.browseMoreContainer {
    animation: fadeIn 0.7s ease-in-out;

    position: fixed;
    // width: 70%;
    top: 14%;
    left: 10%;
    // transform: translateX(-50%);
    background-color: #fff;
    display: flex;
    border-radius: 6px;
    z-index: 999;

    @media all and (max-width: 2700px) {
      left: 7%;
      top: 8%;
    }

    @media all and (max-width: 2500px) {
      left: 9.5%;
      top: 9%;
    }

    @media all and (max-width: 1980px) {
      top: 11%;
      left: 9.5%;

    }

    @media all and (max-width: 1768px) {
      top: 13%;
      left: 9.5%;

    }

    &::after {
      content: "";
      top: 81px;
      /* width: 7px; */
      /* height: 4px; */
      border-color: transparent transparent #fff;
      border-style: solid;
      border-width: 0px 9px 15px;
      position: fixed;
      /* right: 2%; */
      /* transform: translateX(-580%); */
      left: 16.5%;

      @media all and (max-width: 2700px) {
        left: 10.5%;
        top: 7%;
      }

      @media all and (max-width: 2500px) {
        left: 11.5%;
        top: 8%;
      }

      @media all and (max-width: 1980px) {
        top: 10%;
        left: 13.5%;
      }

      @media all and (max-width: 1768px) {
        top: 12%;

      }

      @media all and (max-width: 1400px) {
        left: 17.5%;

      }

      // @media all and (max-width: 1900px) {
      //   top: 13%;
      //   left: 13.5%;
      // }
    }

    h1.categoryName {
      font-size: 14px;
      margin-bottom: 20px;
      color: var(--grey-color);
    }

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      li {
        margin-bottom: 8px;
        cursor: pointer;

        &:hover {
          span {
            color: var(--text-hover);
          }
        }

        span {
          font-size: 14px;
          color: #10141f;
          transition: 0.3s ease-in-out;
        }
      }
    }

    div.left {
      // width: 22%;
      padding: 30px 25px;
      overflow-y: scroll;
      height: 500px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(125, 128, 128);
      }
    }

    div.center {
      width: 40%;
      background-color: #f0f3f5;
      padding: 30px 25px;

      div.sections {
        display: flex;
        justify-content: space-between;

        div.leftSection {
          width: 50%;
        }

        div.rightSection {
          width: 50%;
        }
      }
    }

    div.right {
      width: 40%;
      padding: 30px 25px;
    }
  }
}

div.browseMore.active {
  display: block;

  @media all and (max-width: 1200px) {
    display: none;
  }

  // animation: fadeIn 0.4s ease-in-out ;
}

div.browseMoreResponsive {
  div.container {
    position: fixed;
    left: -50%;
    width: 35%;
    background-color: var(--secondary-bg-color);
    padding: 35px 30px;
    z-index: 9999;
    top: 0;
    bottom: 0;
    overflow: scroll;
    transition: 0.5s ease-in-out;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    @media all and (max-width: 1200px) {
      &.active {
        left: 0;
      }
    }

    @media all and (max-width: 980px) {
      width: 45%;
    }

    @media all and (max-width: 768px) {
      width: 100%;
      left: -100%;
    }

    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.small {
        display: none;
      }

      @media all and (max-width: 768px) {
        &.large {
          display: none;
        }

        &.small {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div.left {
            display: flex;
            align-items: center;
            width: 20%;

            svg {
              width: 22px !important;
              height: 22px !important;
              color: #fff;
              margin-right: 10px;
            }

            span {
              color: #fff;
              font-size: 14px;
              font-weight: 700;
            }
          }

          div.right {
            width: 50%;
            display: flex;
            justify-content: right;

            &.increase {
              width: 100%;
            }

            div.inputContainer {
              border: 1px solid #9e9fa4;
              padding: 8px 10px;
              display: flex;
              align-items: center;
              border-radius: 8px;
              width: 3.5rem;
              justify-content: center;
              transition: 0.2s ease-in-out;
              cursor: pointer;

              input {
                display: none;
              }

              &.showInput {
                width: 100%;
                justify-content: space-between;

                input {
                  display: block;
                  outline: none;
                  border: none;
                  background-color: transparent;
                  width: 93%;
                  color: #fff;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      div.left {
        h1 {
          color: var(--text-hover);
          font-size: 15px;
          font-weight: 700;
        }
      }

      div.right {
        svg {
          color: #fff;
          width: 23px !important;
          height: 23px !important;
        }
      }
    }

    div.language {
      color: var(--white-color);
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        display: inline-block;
        margin-left: 6px;
        font-size: 15px;
        font-weight: 500;
      }
    }

    div.top {
      margin-top: 20px;
      padding-bottom: 30px;
      border-bottom: 1px solid #3d3e43;

      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }

      ul {
        li {
          margin-bottom: 8px;

          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }

    div.center {
      padding: 30px 0;
      border-bottom: 1px solid #3d3e43;

      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }

      ul {
        li {
          margin-bottom: 8px;

          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }

    div.bottom {
      padding: 30px 0;

      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }

      ul {
        li {
          margin-bottom: 8px;

          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
