div.notFound{
    padding: 60px 0;
    @media all and (max-width:768px){
        padding: 80px 0;
    }
    @media all and (max-width:480px){
        padding: 100px 0;
    }
    div.imageContainer{
        width: 40%;
        margin: 0 auto;
        @media all and (max-width:768px){
            width: 50%;
        }
        @media all and (max-width:480px){
            width: 60%;
        }
        img{
            width: 100%;
            display: block;
        }
    }
    div.content{
        text-align: center;
        padding: 0 200px;
        @media all and (max-width:2600px){
            padding: 0 150px ;
        }
        @media all and (max-width:1600px){
            padding: 0 200px ;
        }
        @media all and (max-width:980px){
            padding: 0 100px;
        }
        @media all and (max-width:768px){
            padding: 0 20px;
        }
       
        
        h1{
            font-size: 80px;
            color: var(--text-hover);
            line-height: 0.8em;
            @media all and (max-width:768px){
                font-size: 70px;
            }
            @media all and (max-width:480px){
                font-size: 60px;
            }
        }
        p{
            font-size: 21px;
            margin-top: 10px;
            color: var(--inner-text-color);
            @media all and (max-width:2600px){
                font-size: 25px;
            }
            @media all and (max-width:1600px){
                font-size: 21px;
            }
            @media all and (max-width:1400px){
                margin: 10px;
            }
            @media all and (max-width:768px){
                font-size: 20px;
            }
            @media all and (max-width:480px){
                font-size: 16px;
            }
            @media all and (max-width:360px){
                font-size: 14px;
            }
        }
        div.buttonContainer { 
            display: flex;
        padding: 18px 20px;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        justify-content: center;
        position: relative;
        width: 20rem;
        margin: 30px auto 0;
        @media all and (max-width:640px){
            width: 15rem;
        }
        @media all and (max-width:480px){
            width: 10rem;
        }
        &:hover{
            div.background{

                transform: scale(1.0476190476,1.0357142857);
            }
        }
            div.background{
                background: var(--text-hover);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
                transition: 0.1s ease-in-out;
                z-index: 1;

            }
            button { 
                background: transparent;
                font-size: 15px;
                color: var( --text-color);
                font-weight: 600;
                position: relative;
                z-index: 2;
                cursor: pointer;


            }
        }
    }
}