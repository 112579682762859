div.howtoUpdateScreen{
    padding: 100px 0 0; 
    display: flex;
    flex-direction: column;
    div.uppersection{
        background-color:var(--white-color);
        padding: 30px 100px 0; 
        @media all and (max-width:980px){
            padding: 30px 0 0;
            }
        
        @media all and (max-width:480px){
                padding: 15px 0px 0;
                }
                div.boxContainer{
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 -4px 8px 0 rgba(0, 0, 0, 0.2),4px 0 8px 0 rgba(0, 0, 0, 0.2),-4px 0 8px 0 rgba(0, 0, 0, 0.2);
                    margin-bottom: 25px;
                    padding: 80px;
                        @media all and (max-width:768px){
                        padding:  20px;   
                        }
                }
        h1.heading{
            color: #005186;
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            font-size: 15px;
            margin-bottom: 20px;
            color: var(--black-color);

        }
         h2{
            margin-bottom: 10px;
         }
         h4{
            color: gray;
         }
         ol{
            padding-left: 60px;
            li{
                margin: 10px 0;
                list-style-type: decimal;
                padding-left: 20px;
                font-size: 15px;
            }
         }
         ul{
            li{
                margin: 10px 0;
                padding-left: 75px;
                font-size: 15px; 
            }
         }
         ul.tips{
            padding-left: 20px;
            li{
                padding: 0;
                font-size: 15px; 
                list-style-type: disc
            }

         }
         p.desc{
            font-weight: 600;
         }
         
    }
    div.lowersection{
        
        background-color: #215ba8;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding: 60px 0 60px 0;
        h3{
            color: var(--white-color);
            text-align: center;
            font-weight: 600;
        }
        div.iconContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0 5%;
            @media all and (max-width:1200px){
                padding: 0 2%;      
                }
            @media all and (max-width:640px){
                padding: 0 10%;
                display: flex;
                flex-wrap: wrap;
            
                }
            @media all and (max-width:480px){
                padding: 0 10% 0 16%;
                    }
            div.container{
                width: 10%;
                margin-left: 5%;
                padding: 10px;
                text-align: center;
                justify-content: center;
                &:first-child{
                    margin-left: 0px;
                }
                @media all and (max-width:980px){
                    width: 12%;
                    }
                @media all and (max-width:640px){
                    width: 25%;
                    }
                @media all and (max-width:480px){
                    width: 48%;
                    margin-left:0;
                    }

                div.appIconContainer{
                 width: 70%;
                 text-align: center;
                 cursor: pointer;
                 img{
                    width: 100%;
                 }
                 @media all and (max-width:1200px){
                    width: 100%;        
                    }
                @media all and (max-width:640px){
                     width: 75%;        
                        }
                 @media all and (max-width:480px){
                    width: 56%;
                            }
                }
                div.androidTvContainer{
                    display: flex;
                    margin-top: 12%;
                    width: 126%;
                    img{
                       width: 100%;
                    }
                    @media all and (max-width:2200px){
                        margin-left: -24%;
                        }
                    @media all and (max-width:1200px){
                        margin-top: 23%;
                        width: 140%;   
                        }
                    @media all and (max-width:640px){
                        width: 125px; 
                        margin-top: 13%;
                        margin-left: -26%;     
                        }
                    @media all and (max-width:480px){
                        margin-left: 0;    
                            }
                    @media all and (max-width:360px){
                        margin-left:-23%;    
                                    }
                }
                div.androidContainer{
                    display: flex;
                    margin-top: 12%;
                    width: 126%;
                    img{
                       width: 100%;
                    }
                  
                    @media all and (max-width:1200px){
                        margin-top: 23%;
                        width: 140%;   
                        }
                    @media all and (max-width:640px){
                        width: 125px; 
                        margin-top: 13%;
                        margin-left: -17%;
                                   
                       }
                    @media all and (max-width:360px){
                        margin-left: -24%;
                       }
                }
                div.Roku{
                    @media all and (max-width:640px){
                        margin-left: 0;        
                        }
                    img{
                       width: 100%;
                    }
                }
                div.fire{
                    @media all and (max-width:640px){
                        margin-left: -10%;
                        }
                    @media all and (max-width:480px){
                         margin-left: 0;
                            }
                }
            }
            
        }
    }
}
