div.videoPlayer {
  position: relative;
  padding: 100px 0 0;
  // overflow: hidden;
    video {
      height: 100% !important;
      display: block !important;
      width: 100% !important;
      outline: rgb(0, 0, 0) !important;
    }  
    
    div.arrowContainer{
    width: 5%;
    cursor: pointer;
    @media all and (min-width:1024px){
      position: absolute;

    }
    h3{
      width:100%;
      background: transparent;
      cursor: pointer;
      svg{
        width: 30px;
        height: 100%;
        fill: white !important; 
      }
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.2);   
       }
  }
 

  div.playerContainer {
    width: 90%;
    margin: 0 auto;
    background-color: var(--black-color);
    position: relative;

    @media all and (min-width:1024px){  
      width: 800px;  
      div.video-js{
        padding-top: 0 !important;
        height: 520px !important;
      } 

    }
    @media all and (min-width:1440px){  
      width: 1089px;  
      div.video-js{
        padding-top: 0 !important;
        height: 612px !important;
      } 

    }
    div.donationContainer{
      div.bannerContainer{
        position: relative;
        div.overlay{
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(000, 000, 000, 0.6);
        }
        div.donate{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 70%;
          text-align: center;
          @media all and (max-width:1200px){
            width: 80%;
          }
          @media all and (max-width:768px){
            width: 100%;
          }
          h1{
            text-align: center;
            color: var(--white-color);
            font-weight: 400;
            margin-bottom: 10px;
            @media all and (max-width:1200px){
              font-size: 25px;
            }
            @media all and (max-width:980px){
              font-size: 20px;
            }
            @media all and (max-width:640px){
              font-size: 14px;
            }
          }
          div.buttonContainer {
            button {
              background-color: var(--button-color);
              color: var(--white-color);
              font-weight: 600;
              padding: 10px 20px;
              border-radius: 6px;
              font-size: 14px;
              cursor: pointer;
              border: 1px solid var(--button-color);
              transition: 0.4s ease-in-out;
              width: 12rem;
              &:hover {
                background-color: transparent;
              }
              @media all and (max-width:640px){
                width: 8rem;
              padding: 8px 20px;
              font-size: 13px;

              }
              @media all and (max-width:480px){
                width: 6rem;
              padding: 5px 20px;
              font-size: 12px;

              }
            }
          }
        }
        img{
          display: block;
          width: 100%;
        }
      }
    }
  }

  // .vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  //     padding-top: 0 !important;
  // }
  // .vjs-fluid:not(.vjs-audio-only-mode) {
  //     padding-top: 0 !important;
  // }
  // .video-js.vjs-fluid:not(.vjs-audio-only-mode){
  //     height: 100% !important;
  // }
  div.videoMetaData {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;
    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;
      @media all and (max-width:980px){
        flex-direction: column;
        align-items: flex-start;
      }
      @media all and (max-width:480px){
       margin-top: 20px;
      }
      h1.videoTitle {
        color: var(--text-color);
        font-size: 30px;
      }
      div.buttonContainer {
        display: flex;
        @media all and (max-width:980px){
          justify-content: flex-start;
          margin-top: 10px;
        }
        button {
          background-color: var(--button-color);
          color: var(--white-color);
          font-weight: 600;
          padding: 10px 20px;
          border-radius: 6px;
          font-size: 14px;
          cursor: pointer;
          border: 1px solid var(--button-color);
          transition: 0.4s ease-in-out;
          &:hover{
            background-color: transparent;
          }
          &.myList {
            margin-right: 20px;
          }
        }
      }
    }
    div.categoriesAndRating {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      div.categories {
        span.category {
          font-size: 16px;
          color: var(--inner-text-color);
          cursor: pointer;
          &::after {
            content: "•";
            margin: 0 5px 0 10px;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      span.rating {
        margin-left: 10px;
        padding: 2px 6px;
        background-color: var(--grey-color);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        border-radius: 3px;
      }
    }
    p.description {
      color: var(--inner-text-color);
      margin-bottom: 20px;
    }
    div.extraInfoContainer {
      div.metaItem {
        margin-bottom: 20px;
        h6.subHeading {
          font-size: 16px;
          color: var(--white-color);
          margin-bottom: 10px;
        }
        div.items {
          width: 30%;
          &.oneRow {
            display: flex;
            width: 60%;
            flex-wrap: wrap;
            span.item {
              margin-right: 10px;
              margin-bottom: 0;
              display: block;
              color: var(--inner-text-color);
              cursor: pointer;
              &:last-child{
                span.comma{
                  display: none;
                }
              }
            }

          }
          span.itemInfo{
            cursor: pointer;
          }
          div.item {
            display: flex;
            justify-content: space-between;
            color: var(--inner-text-color);
            margin-bottom: 6px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  div.upNextContainer{
    width: 90%;
    margin: 0 auto;
    h1.heading{
      text-align: left;
      color: var(--heading-color);
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-wrap: wrap;
      @media all and (max-width:980px){
       width: 100%;
      }
      @media all and (max-width:768px){
        font-size: 24px;
      }
    }
    div.upNext{
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-wrap: wrap;
      margin-top: 20px;
      @media all and (max-width:980px){
        width: 100%;
      }
      div.item{
        width: 32.5%;
        margin-right: 10px;
        &:last-child{
          margin-right: 0;
          margin-bottom: 0;
        }
        @media all and (max-width:1200px){
          width: 32%;
        }
        @media all and (max-width:768px){
          width: 48.5%;
          margin-bottom: 20px;
          &:nth-child(2n){
            margin-right: 0;
          }
        }
        @media all and (max-width:480px){
          width: 100%;
          margin-right: 0;
          &:nth-child(2n){
            margin-right: 0;
          }
        }
      }
    }
  }
  span.line {
    height: 1px;
    background-color: var(--border-color);
    width: 100%;
    display: block;
    margin-top: 70px;
  }
  div.files{
    width: 90%;
    margin: 0 auto;
  }
  div.commentsContainer {
    width: 70%;
    margin: 0 auto;
    padding: 60px 0;
    @media all and (max-width:1200px){
      width: 80%;
    }
    @media all and (max-width:768px){
      width: 90%;
    }
    @media all and (max-width:640px){
      width: 100%;
    }
    div.top {
      div.head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 10px;
        span.commentsCount {
          color: var(--white-color);
          font-size: 22px;
          @media all and (max-width:640px){
            font-size: 16px;
          }
        }

        div.sortContainer {
          select {
            background-color: transparent;
            border: 1px solid var(--border-color);
            padding: 10px 15px;
            color: var(--white-color);
            font-size: 16px;
            border-radius: 6px;
            option {
              color: var(--black-color);
            }
          }
        }
      }
      
      div.textContainer {
        display: flex;
        padding: 40px 0 20px;
        border-bottom: 1px solid var(--border-color);

        div.imageContainer {
          margin-right: 20px;
          width: 45px;
          height: 45px;
            img{
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          span.nameAvatar {
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white-color);
            background-color: green;
            border-radius: 50%;
          }
        }
        div.inputContainer {
          width: 90%;
          textarea {
            display: block;
            width: 100%;
            height: 45px;
            padding: 10px 15px;
            border-radius: 5px;
            margin-bottom: 20px;
            &:focus-visible {
              border: 3px solid var(--button-color) !important;
              outline: none;
            }
          }
          div.actions {
            display: flex;
            justify-content: space-between;

            p {
              font-size: 14px;
              color: var(--text-color);
              span {
                color: var(--grey-color);
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }

            div.buttonContainer {
              button.submit {
                background-color: var(--button-color);
                color: var(--white-color);
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
                border: 1px solid var(--button-color);
                transition: 0.4s ease-in-out;
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    div.bottom {
      div.comments {
        div.eachComment{
          border-bottom: 1px solid var(--border-color);
          &:last-child{
            border: none;
          }
          div.containers {
            padding: 40px 0 25px;
            
            div.replyMessagesContainer {
              padding: 40px 0 0;
             
              div.containers{
                padding: 0 0 0;
                display: flex;
                border: none;
              }
             
            }
            div.commentItem {
              display: flex;
              &:last-child{
                border: none;
              }
    
              div.left {
                width: 45px;
                margin-right: 20px;
                div.profileImageContainer{
                  width: 100%;
                  height: 45px;
                  @media all and (max-width:640px){
                    height: 41px;
                  }
                  img{
                    width: 100%;
                    display: block;
                    height: 100%;
                  border-radius: 50%;

                  }
                }
                span.nameAvatar {
                  width: 100%;
                  height: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: var(--white-color);
                  background-color: green;
                  border-radius: 50%;
                }
              }
    
              div.right {
                width: 90%;
                @media all and (max-width:640px){
                  width: 96%;
                }
                h1.name {
                  font-size: 16px;
                  color: var(--white-color);
                }
    
                span.time {
                  font-size: 13px;
                  color: var(--inner-text-color);
                  font-weight: 600;
                  display: inline-block;
                  margin-bottom: 20px;
                }
    
                p.comment {
                  font-size: 16px;
                  color: var(--white-color);
                  margin-bottom: 20px;
                }
    
                div.actions {
                  display: flex;
                  span.item {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    font-size: 11px;
                    color: var(--inner-text-color);
                    transition: 0.4s ease-in-out;
                    cursor: pointer;
                    &:hover {
                      opacity: 0.6;
                    }
                    &:last-child {
                      margin-right: 0;
                    }
                    span {
                      svg {
                        width: 15px !important;
                        height: 15px !important;
                        color: rgb(190, 190, 187);
                        fill: rgb(190, 190, 187);
                        margin-right: 5px;
                      }
                    }
                  }
                }
              }
            }
           
          }

        }
      }
    }
  }
}
