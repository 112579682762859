div.playListPage{ 
    padding: 130px 0 40px;
    div.headingSection {
        padding-bottom: 60px;
        position: absolute;
        top: 84px;
        z-index: 8;
        left: 60px;
        @media all and (max-width: 640px) {
            top: 50px;
            z-index: 8;
            left: 20px;
          }
          div.b2b-warning {
            color: #fff;
            background: #f70606;
            padding: 10px 10px 5px 10px;
            border-radius: 2px;
            text-align: center;
            font-size: medium;
            font-weight: 600;
            display: flex;
            justify-content: space-evenly;
    
            @media all and (max-width: 640px) {
              justify-content: start;
              font-size: medium;
            }
    
            @media all and (max-width: 320px) {
              font-size: small;
            }
    
            .message {
              display: flex;
              flex-direction: row;
    
              @media all and (max-width: 640px) {
                flex-direction: column;
              }
    
              .secondSentence {
                margin-left: 5px;
    
                @media all and (max-width: 640px) {
                  margin-left: 15px;
                }
              }
            }
          }
        h1.heading {
          font-size: 40px;
          margin-bottom: 10px;
          margin-top: 20px;
          color: var(--text-color);
          overflow: hidden;
          text-overflow: ellipsis;
          @media all and (max-width: 640px) {
            font-size: 22px;
            margin-top: 50px;
          }
        }
        p.desc {
          font-size: 18px;
          color: var(--inner-text-color);
          overflow: hidden;
          text-overflow: ellipsis;
          @media all and (max-width: 640px) {
            font-size: 14px;
          }
        }
      }
      div.headingSection-wrap {
        padding-bottom: 60px;
        width: 55%;
        position: absolute;
        top: 84px;
        z-index: 8;
        left: 60px;
     
          @media (max-width: 1280px) {
            width: 40%;
            right: 100px;
            top: 63px;
          
          }
          @media (max-width: 980px) {
            width: 80%;
            top: 231px;
            right: 107px;
          }
          @media (max-width: 768px) {
            padding-bottom: 60px;
            position: absolute;
            top: 298px;
            z-index: 8;
            left: 39px;
            width: 82%;
          }
          @media (max-width: 640px) {
            top: 250px;
            left: 39px;
          }
          @media (max-width: 480px) {
            top: 200px;
            left: 22px;
          }
          @media (max-width: 360px) {
            top: 163px;
            left: 22px;
          }
          div.b2b-warning {
            color: #fff;
            background: #f70606;
            padding: 10px 10px 5px 10px;
            border-radius: 2px;
            text-align: center;
            font-size: medium;
            font-weight: 600;
            display: flex;
            justify-content: space-evenly;
            @media all and (max-width: 980px) {
              margin-top: 10%;
            }
            @media all and (max-width: 640px) {
              justify-content: start;
              font-size: medium;
            }
    
            @media all and (max-width: 320px) {
              font-size: small;
            }
    
            .message {
              display: flex;
              flex-direction: row;
              @media all and (max-width: 1200px) {
                flex-direction: column;
              }
              @media all and (max-width: 640px) {
                flex-direction: column;
              }
    
              .secondSentence {
                margin-left: 5px;
    
                @media all and (max-width: 640px) {
                  margin-left: 15px;
                }
              }
            }
          }
        h1.heading {
          font-size: 40px;
          margin-bottom: 10px;
          margin-top: 20px;
          color: var(--text-color);
          overflow: hidden;
          text-overflow: ellipsis;
          @media all and (max-width: 640px) {
            font-size: 18px;
            margin-top: 50px;
          }
        }
        p.desc {
          font-size: 16px;
          color: var(--inner-text-color);
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 2440px) {
            font-size: 16px;
          }
          @media (max-width: 1280px) {
            font-size: 12px;
          }
          @media (max-width: 768px) {
            font-size: 9px;
          }
          @media all and (max-width: 640px) {
            font-size: 8px;
            

          }
          @media all and (max-width: 480px) {
            font-size: 6px;
          }
          @media all and (max-width: 360px) {
            font-size: 4px;
          }
        }
      }
      div.backgroundContainer {
        width: 100%;
        display: block;
        height: 508px;
        position: relative;
        @media all and (max-width: 768px) {
          height: 556px;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          @media all and (max-width: 768px) {
            object-fit: cover;
          }
        }
      }
      div.bottomGradient {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
        height: 100%;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        background: linear-gradient(
            78.83deg,
            rgb(16, 20, 31) 0%,
            rgba(16, 20, 31, 0) 61.46%
          ),
          linear-gradient(
            360deg,
            rgb(16, 20, 31) 0%,
            rgba(16, 20, 31, 0) 48.44%,
            rgba(16, 20, 31, 0.4) 100%
          );
      }
    
        div.playlistBody{
        
       
            div.playlistContainer{
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                
                 div.playlistItems{ 
                    width: 30%;
                    margin-bottom: 30px;
                    margin-right: 20px;
                    // margin-top: 20px;
                   
                    &:nth-child(3n){
                        margin-right: 0px;
                    }
                    @media all and (max-width:2600px){
                        margin-right: 30px;
                        width: 15.5%;
                        &:nth-child(3n){
                            margin-right: 30px;
                        }
                        &:nth-child(6n){
                            margin-right: 0px;
                        }
                    }
                    @media all and (max-width:2500px){
                        width: 15.8%;
                        margin-right: 20px;
                        &:nth-child(3n){
                            margin-right: 20px;
                        }
                        &:nth-child(6n){
                            margin-right: 0px;
                        }
                    }
                    @media all and (max-width:2200px){
                        width: 19%;
                        &:nth-child(5n){
                            margin-right: 0px;
                        }
                        &:nth-child(6n){
                            margin-right: 20px;
                        }
                    }
                    @media all and (max-width:1980px){
                        width: 23.7%;
                        &:nth-child(4n){
                            margin-right: 0px;
                        }
                        &:nth-child(5n){
                            margin-right: 20px;
                        }
                    }
                    @media all and (max-width:1768px){
                        width: 23.8%;
                        &:nth-child(4n){
                            margin-right: 0px;
                        }
                    }
                    @media all and (max-width:1600px){
                        width: 23.8%;
                       
                        &:nth-child(4n){
                            margin-right: 0px;
                        }
                    }
                    @media all and (max-width:1400px){
                        width:31.5%;
                       
                        &:nth-child(3n){
                            margin-right: 0px;
                        }
                        &:nth-child(4n){
                            margin-right: 20px;
                        }
                    }
                    @media all and (max-width:1200px){
                        width: 31.3%;
                        &:nth-child(3n){
                            margin-right: 0px;
                        }
                        &:nth-child(4n){
                            margin-right: 20px;
                        }
                    }
                    @media all and (max-width:1080px){
                        width: 31%;
                        margin-right: 20px;
                        &:nth-child(5n){
                            margin-right: 20px;
                        }
                        &:nth-child(3n){
                            margin-right: 0px;
                        }
                        &:nth-child(2n){
                            margin-right: 20px;
                        }
                    }
                    @media all and (max-width:980px){
                        width: 47.8%;
                        margin-right: 25px;
                        &:nth-child(5n){
                            margin-right: 25px;
                        }
                        &:nth-child(3n){
                            margin-right: 25px;
                        }
                        &:nth-child(2n){
                            margin-right: 0px;
                        }
                    }
                    @media all and (max-width:768px){
                        width: 47.8%;
                        margin-right: 20px;
                        &:nth-child(5n){
                            margin-right: 20px;
                        }
                        &:nth-child(3n){
                            margin-right: 20px;
                        }
                        &:nth-child(2n){
                            margin-right: 0px;
                        }
                    }
                    @media all and (max-width:640px){
                        width: 99%;
                        margin-bottom: 10px;
                        margin-right: 0px;
                        &:nth-child(3n){
                            margin-right: 0px;
                        }
                        &:nth-child(5n){
                            margin-right: 0px;
                        }
                    }
                   
                 }

               
                
            }
        }
   
        

  
       
}
