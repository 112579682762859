div.showCardContainer {
  width: 100%;
  position: relative;
  div.showsSubscriptions {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media all and (max-width:1200px){
        display: none;
      }
    div.items {
      width: 5rem;
      margin-right: 10px;
      background-color: var(--yellow-color);
      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      color: var(--text-color);
      margin-bottom: 10px;
      border-radius: 30px;
      &.free {
        background-color: var(--success-color);
      }
      @media all and (max-width:1200px){
        font-size: 8px;
        width: 4rem;
      }
    }
  }
  div.continueWatchingRemove{
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 8;
    background-color: rgba(000, 000, 000, 0.72);
    padding: 6px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      stroke: #fff !important;
    }
  }
  div.premiumStatus {
    div.premium {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      background-color: rgba(8, 25, 43, 0.65);
      // padding: 6px 6px 3px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      @media all and (max-width: 1200px) {
        width: 26px;
      }
      svg {
        width: 18px !important;
        height: 18px !important;
        @media all and (max-width: 1200px) {
          width: 12px !important;
          heigth: 12px !important;
        }
      }
    }
    div.free {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      width: 23px;
      background-color: rgba(000, 000, 000, 0.32);
      padding: 3px;
      border-radius: 2px;
      
      img {
        display: block;
        width: 100%;
      }
    }
  }
  &.showContents{
  cursor: pointer;
  h2.time {
    font-size: 12px;
  }
    &:hover {
      div.imageContainer {
        div.extraActions {
          visibility: visible;
          animation: fadeIn 0.2s ease-in-out forwards;
        }
        img {
          transform: scale(1.1);
        }
      }
      div.metaData {
        h1.title {
          color: var(--text-hover);
        }
      }
    }
  }
  div.imageContainer {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    &.minHeight{
      min-height: 180px;
      @media all and (max-width:1400px){
        min-height: 141px;
      }
      @media all and (max-width:1200px){
        min-height: 115px;
      }

    }
    // background-color: red;
    div.loading{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      overflow: hidden;
  }
    &.imageLoaded{

    }
    // &::before{
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-color: red;
    //   min-height: 400px;
    // }
    img {
      width: 100%;
      display: block;
      border-radius: 3px;
      transition: 0.2s ease-in-out;
      // position: relative;
      // &.landscape{
      //   min-height: 130px;
      //   background: black;
      // }
      // &.portrait{
      //   min-height: 420px;
      //   background: black;
      // }
    }
    div.continueWatchingBar{
      position: absolute;
      bottom: 0;
      height: 6px;
      background-color: var(--text-color);
      width: 100%;
      div.line{
        height: 100%;
        background-color: var(--button-hover);
      }
    }
    div.extraActions {
        visibility: hidden;
        animation: fadeOut 0.2s ease-in-out forwards;

      div.showCardOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--overlay);
        z-index: 8;
      }
      div.playIconContainer {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 3px solid var(--text-color);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        @media all and (min-width: 320px) {
          width: 30px;
          height: 30px;
          svg {
            width: 13px !important;
            height: 25px !important;
          }
        }
        @media all and (min-width: 320px) {
          width: 50px;
          height: 50px;
        }
        &:hover {
          border-color: var(--text-hover);
          svg {
            color: var(--text-hover);
          }
        }
        svg {
          width: 13px !important;
          height: 13px !important;
          color: var(--text-color);
          transition: 0.3s ease-in-out;
          @media all and (min-width: 320px) {
            width: 13px !important;
            height: 13px !important;
          }
          @media all and (min-width: 480px) {
            width: 25px !important;
            height: 18px !important;
          }
        }
      }
      div.addToList {
        position: absolute;
        bottom: 3px;
        width: 96%;
        z-index: 9;
        left: 50%;
        border-radius: 8px;
        transform: translateX(-50%);
        padding: 6px;
        text-align: center;
        box-shadow: rgba(62, 1, 1, 0.03) 0px 16px 24px;
        background-color: rgba(49, 46, 46, 0.3);
                backdrop-filter: blur(8px);
        @media all and (min-width: 320px) {
          padding: 0;
          border-radius: 2px;
          width: 97%;
          span {
            font-size: 8px;
          }
        }
        @media all and (min-width: 480px) {
          padding: 3px;
          span {
            font-size: 10px;
          }
        }
        &:hover {
          span {
            color: var(--text-hover);
          }
        }
        span {
          color: var(--text-color);
          font-size: 10px;
          transition: 0.3s ease-in-out;
          @media all and (min-width: 480px) {
            font-size: 11px;
          }
        }
      }
    }
  }
  div.metaData {
    margin-top: 10px;
    color: var(--inner-text-color);

    h1.title {
      color: var(--text-color);
      font-size: 16px;
      transition: 0.3s ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // min-height: 40px;
      @media all and (min-width: 320px) {
        font-size: 12px;
      }
      @media all and (min-width: 640px) {
        font-size: 14px;
      }
    }
    div.datas {
      display: flex;
      justify-content: space-between;
      @media all and (max-width: 980px) {
        display: none;
      }

      div.left {
        span {
          font-size: 12px;
          margin-right: 6px;
          &.duration {
            &.showDot{

              &::before {
                content: "·";
                margin-right: 6px;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      div.right {
        span {
          font-size: 14px;
          display: inline-block;
          padding: 1px 6px;
          background-color: rgba(150, 153, 163, 0.32);
          font-size: 12px;
          font-weight: 700;
          color: var(--text-color);
          border-radius: 4px;
          &.certificate {
            @media all and (min-width: 320px) {
              font-size: 9px;
            }
          }
        }
      }
    }
    div.genres {
      font-size: 12px;
      display: flex;
      flex-wrap: wrap;
      @media all and (min-width: 320px) {
        display: none;
      }
      @media all and (min-width: 768px) {
        display: flex;
      }
      span {
        margin-right: 6px;
        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }
        &::before {
          content: "·";
          margin-right: 6px;
        }
      }
    }
    p.description {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      height:30px;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
